/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getMarket = /* GraphQL */ `
  query GetMarket($id: ID!) {
    getMarket(id: $id) {
      id
      name
      products {
        items {
          id
          description
          MarketProducts
          price
          shipped
          owner
          createdAt
          updatedAt
        }
        nextToken
      }
      tags
      owner
      createdAt
      updatedAt
    }
  }
`;
export const listMarkets = /* GraphQL */ `
  query ListMarkets(
    $filter: ModelMarketFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMarkets(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        products {
          items {
            id
            description
            price
            shipped
            owner
            file {
              key
            }
            createdAt
          }
          nextToken
        }
        tags
        owner
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const searchMarkets = /* GraphQL */ `
  query SearchMarkets(
    $filter: SearchableMarketFilterInput
    $sort: [SearchableMarketSortInput]
    $limit: Int
    $nextToken: String
    $from: Int
    $aggregates: [SearchableMarketAggregationInput]
  ) {
    searchMarkets(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
      aggregates: $aggregates
    ) {
      items {
        id
        name
        products {
          nextToken
        }
        tags
        owner
        createdAt
        updatedAt
      }
      nextToken
      total
      aggregateItems {
        name
        result {
          ... on SearchableAggregateScalarResult {
            value
          }
          ... on SearchableAggregateBucketResult {
            buckets {
              key
              doc_count
            }
          }
        }
      }
    }
  }
`;
export const getProduct = /* GraphQL */ `
  query GetProduct($id: ID!) {
    getProduct(id: $id) {
      id
      description
      MarketProducts
      market {
        id
        name
        products {
          nextToken
        }
        tags
        owner
        createdAt
        updatedAt
      }
      file {
        bucket
        region
        key
      }
      price
      shipped
      owner
      createdAt
      updatedAt
    }
  }
`;
export const listProducts = /* GraphQL */ `
  query ListProducts(
    $filter: ModelProductFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listProducts(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        description
        MarketProducts
        market {
          id
          name
          tags
          owner
          createdAt
          updatedAt
        }
        file {
          bucket
          region
          key
        }
        price
        shipped
        owner
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getUser = /* GraphQL */ `
  query GetUser($id: ID!,   $limit: Int, $sortDirection: ModelSortDirection) {
    getUser(id: $id) {
      id
      username
      email
      registered
      admin
      orders ( limit:$limit, sortDirection: $sortDirection){
        items {
          id
          UserOrders
          price
          init_price
          tax_price
          shipping_price
          shipped
          paymentIntent
          status
          createdAt
          updatedAt
          shippingAddress {
          city
          country
          address_line1
          address_state
          address_zip
          phone_number
        }
          owner
          products {
          items {
            id
            description
            price
            quantity
            shipped
            instock
            owner
            file {
              key
            }
            createdAt
          }
          nextToken
          }
        }
        nextToken
      }
      createdAt
      updatedAt
      owner
    }
  }
`;
export const getOrder = /* GraphQL */ `
  query GetOrder($id: ID!) {
    getOrder(id: $id) {
      id
      UserOrders
      products {
        items {
          id
          orderMyProduct
          description
          price
          quantity
          shipped
          owner
          createdAt
          updatedAt
        }
        nextToken
      }
      user {
        id
        username
        email
        registered
        admin
        orders {
          nextToken
        }
        createdAt
        updatedAt
        owner
      }
      price
      shipped
      paymentIntent
      status
      shippingAddress {
        city
        country
        address_line1
        address_state
        address_zip
      }
      createdAt
      updatedAt
      owner
    }
  }
`;
export const listOrders = /* GraphQL */ `
  query ListOrders(
    $filter: ModelOrderFilterInput
    $limit: Int
    $nextToken: String
     ) {
    listOrders(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        
        UserOrders
        products {
          items {
            id
            description
            price
            quantity
            shipped
            instock
            owner
            file {
              key
            }
            createdAt
          }
          nextToken
        }
     
        user {
          id
          username
          email
          registered
          admin
          createdAt
          updatedAt
          owner
        }
        price
        init_price
        shipped
        paymentIntent
        status
        shippingAddress {
          city
          country
          address_line1
          address_state
          address_zip
        }
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;


export const ByStatusOrders = /* GraphQL */ `
  query ByStatusOrders(
    $filter: ModelOrderFilterInput
    $status: String!
    $limit: Int
    $sortDirection: ModelSortDirection
    $nextToken: String
     ) {
      ByStatusOrders(filter: $filter, limit: $limit, status: $status, sortDirection:$sortDirection, nextToken: $nextToken) {
      items {
        id
        
        UserOrders
        products {
          items {
            id
            description
            price
            quantity
            shipped
            instock
            owner
            file {
              key
            }
            createdAt
          }
          nextToken
        }
     
        user {
          id
          username
          email
          registered
          admin
          createdAt
          updatedAt
          owner
        }
        price
        init_price
        tax_price
        shipping_price
        shipped
        paymentIntent
        status
        shippingAddress {
          city
          country
          address_line1
          address_state
          address_zip
          phone_number
        }
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const ByDateOrders = /* GraphQL */ `
  query ByDateOrders(
    $filter: ModelOrderFilterInput
    $UserOrders: ID!
    $limit: Int
    $sortDirection: ModelSortDirection
    $nextToken: String
     ) {
      ByDateOrders(filter: $filter, limit: $limit, UserOrders: $UserOrders, sortDirection:$sortDirection, nextToken: $nextToken) {
      items {
        id
        username
      email
      registered
      admin
        UserOrders
        products {
          items {
            id
            description
            price
            quantity
            shipped
            instock
            owner
            file {
              key
            }
            createdAt
          }
          nextToken
        }
     
        user {
          id
          username
          email
          registered
          admin
          createdAt
          updatedAt
          owner
        }
        price
        init_price
        shipped
        paymentIntent
        status
        shippingAddress {
          city
          country
          address_line1
          address_state
          address_zip
        }
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;


export const getMyProduct = /* GraphQL */ `
  query GetMyProduct($id: ID!) {
    getMyProduct(id: $id) {
      id
      orderMyProduct
      order {
        id
        UserOrders
        products {
          nextToken
        }
        user {
          id
          username
          email
          registered
          admin
          createdAt
          updatedAt
          owner
        }
        price
        shipped
        paymentIntent
        status
        shippingAddress {
          city
          country
          address_line1
          address_state
          address_zip
        }
        createdAt
        updatedAt
        owner
      }
      description
      file {
        bucket
        region
        key
      }
      price
      quantity
      shipped
      owner
      createdAt
      updatedAt
    }
  }
`;
export const listMyProducts = /* GraphQL */ `
  query ListMyProducts(
    $filter: ModelMyProductFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMyProducts(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        orderMyProduct
        order {
          id
          UserOrders
          price
          shipped
          paymentIntent
          status
          createdAt
          updatedAt
          owner
        }
        description
        file {
          bucket
          region
          key
        }
        price
        quantity
        shipped
        owner
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
