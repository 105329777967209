import React, { useContext } from 'react';
import { PlusCircleIcon} from './Icon'
import { CartContext } from './contexts/CartContext';
import { convertCentsToDollar, convertDollarsToCents } from '../utils'
import { formatNumber } from  '../utils';

import config from '../aws-exports'

const {
    aws_user_files_s3_bucket_region: region,
    aws_user_files_s3_bucket: bucket
} = config


const OrderItem = ({product}) => {

    const { increase, decrease, removeProduct } = useContext(CartContext);
    const url = `https://${bucket}.s3.${region}.amazonaws.com/public/${product.file.key}`

    return ( 
        <div className="row no-gutters py-2">
            <div className="col-sm-2 p-2">
                <img
                alt={product.name}
                style={{margin: "0 auto", maxHeight: "50px"}} 
                src={url} className="img-fluid d-block"/>
            </div>
            <div className="col-sm-4 p-2">
                <h5 className="mb-1">{product.name}</h5>
                <p className="mb-1">Price: {convertCentsToDollar(product.price)} </p>
                
            </div>
            <div className="col-sm-2 p-2 text-center ">
                 <p className="mb-0">Qty: {product.quantity}</p>
            </div>
        
            <div className="col-sm-4 p-2 text-right">
             <p className="mb-1">Sum Price: { convertCentsToDollar( product.quantity * product.price)} </p>

                 
                 
            </div>
        </div>
     );
}
 
export default OrderItem;