import React from "react";
import { API, graphqlOperation } from "aws-amplify";
import gql from 'graphql-tag';
import { onCreateProduct, onUpdateProduct, onDeleteProduct } from
  "../graphql/subscriptions"
import { Loading, Tabs, Icon } from "element-react";
import { Link } from "react-router-dom"
import NewProduct from "../components/NewProduct"
import Product from "../components/Product"
import { Card,  Tag } from "element-react";
import MyCard from "../components/ProductItem"


import MarketList from "../components/MarketList";

export const getMarket = `query GetMarket($id: ID!, $lid:Int!) {
  getMarket(id: $id) {
    id
    name
    products (limit: $lid){
      items {
        id
        description
        price
        shipped
        owner
        file {
          key
        }
        createdAt
      }
      nextToken
    }
    tags
    owner
    createdAt
  }
}
`;



class gmarkpage extends React.Component {
  state = {
    market: null,
    isLoading: true,
    isMarketowner: false
  };

  componentWillUnmount() {
    // fix Warning: Can't perform a React state update on an unmounted component
    this.setState = (state,callback)=>{
        return;
    };
}
  componentDidMount() {
    this.handleGetMarket();
  }
  
  componentDidUpdate(prevProps) {
    if (this.props.marketId !== prevProps.marketId) {
        this.handleGetMarket();
    }

  }

  checkMarketOwner = () => {
    const { user } = this.props
    const { market } = this.state;

    if (user) {
      this.setState({ isMarketowner: user.username === market.owner })
    }
  }




  handleGetMarket = async () => {
    const input = {
      id: this.props.marketId
    }
    console.log({ input });
    const result = await API.graphql( {
        query: getMarket, 
        variables: {  id: this.props.marketId , lid :100},
        authMode: "API_KEY"
      } );
    
    console.log({ result });
    this.setState({ market: result.data.getMarket, isLoading: false }, () => {
      this.checkMarketOwner()
    });
  }

  render() {
      const { market, isLoading, isMarketowner } = this.state;
      return isLoading ? (
          <Loading fullscreen={true} />
      ) : (
          <>
              <h2 className="header">
                  <img
                      src="https://img.icons8.com/color/48/000000/product.png"
                      alt="Store Icon" className="large-icon" />
                    Market
                    </h2>



              <div key={market.id} className="my-2">
                  <Card
                      bodyStyle={{
                          padding: "0.7em",
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'space-between'
                      }}
                  >

                      <div>
                          <span className="flex">
                              <Link className="link" to={`/markets/${market.id}`} >
                                  {market.name}
                              </Link>

                              <img src="https://img.icons8.com/color/48/000000/shopping-cart-loaded.png"
                                  alt="Shopping Cart" />
                          </span>

                          <div style={{ color: "var(--lightSquidInk)" }}>
                              {market.owner}
                          </div>

                      </div>
                      <div>
                          {market.tags && market.tags.map(tag => (
                              <Tag key={tag} type="danger" className="mx-1" >
                                  {tag}
                              </Tag>
                          ))}
                      </div>

                  </Card>


                  <div className="productgrid" >
                      {market.products.items.map(product => (
                          <MyCard key={product.id} product={product} />

                      ))}


                    
                  </div>

              </div>

          </>

      )

  }
}

export default gmarkpage;
