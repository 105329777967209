/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createMarket = /* GraphQL */ `
  mutation CreateMarket(
    $input: CreateMarketInput!
    $condition: ModelMarketConditionInput
  ) {
    createMarket(input: $input, condition: $condition) {
      id
      name
      products {
        items {
          id
          description
          MarketProducts
          price
          shipped
          owner
          createdAt
          updatedAt
        }
        nextToken
      }
      tags
      owner
      createdAt
      updatedAt
    }
  }
`;
export const updateMarket = /* GraphQL */ `
  mutation UpdateMarket(
    $input: UpdateMarketInput!
    $condition: ModelMarketConditionInput
  ) {
    updateMarket(input: $input, condition: $condition) {
      id
      name
      products {
        items {
          id
          description
          MarketProducts
          price
          shipped
          owner
          createdAt
          updatedAt
        }
        nextToken
      }
      tags
      owner
      createdAt
      updatedAt
    }
  }
`;
export const deleteMarket = /* GraphQL */ `
  mutation DeleteMarket(
    $input: DeleteMarketInput!
    $condition: ModelMarketConditionInput
  ) {
    deleteMarket(input: $input, condition: $condition) {
      id
      name
      products {
        items {
          id
          description
          MarketProducts
          price
          shipped
          owner
          createdAt
          updatedAt
        }
        nextToken
      }
      tags
      owner
      createdAt
      updatedAt
    }
  }
`;
export const createProduct = /* GraphQL */ `
  mutation CreateProduct(
    $input: CreateProductInput!
    $condition: ModelProductConditionInput
  ) {
    createProduct(input: $input, condition: $condition) {
      id
      description
      MarketProducts
      market {
        id
        name
        products {
          nextToken
        }
        tags
        owner
        createdAt
        updatedAt
      }
      file {
        bucket
        region
        key
      }
      price
      shipped
      owner
      createdAt
      updatedAt
    }
  }
`;
export const updateProduct = /* GraphQL */ `
  mutation UpdateProduct(
    $input: UpdateProductInput!
    $condition: ModelProductConditionInput
  ) {
    updateProduct(input: $input, condition: $condition) {
      id
      description
      MarketProducts
      market {
        id
        name
        products {
          nextToken
        }
        tags
        owner
        createdAt
        updatedAt
      }
      file {
        bucket
        region
        key
      }
      price
      shipped
      owner
      createdAt
      updatedAt
    }
  }
`;
export const deleteProduct = /* GraphQL */ `
  mutation DeleteProduct(
    $input: DeleteProductInput!
    $condition: ModelProductConditionInput
  ) {
    deleteProduct(input: $input, condition: $condition) {
      id
      description
      MarketProducts
      market {
        id
        name
        products {
          nextToken
        }
        tags
        owner
        createdAt
        updatedAt
      }
      file {
        bucket
        region
        key
      }
      price
      shipped
      owner
      createdAt
      updatedAt
    }
  }
`;
export const registerUser = /* GraphQL */ `
  mutation RegisterUser(
    $input: CreateUserInput!
    $condition: ModelUserConditionInput
  ) {
    registerUser(input: $input, condition: $condition) {
      id
      username
      email
      registered
      admin
      orders {
        items {
          id
          UserOrders
          price
          shipped
          paymentIntent
          status
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      createdAt
      updatedAt
      owner
    }
  }
`;
export const updateUser = /* GraphQL */ `
  mutation UpdateUser(
    $input: UpdateUserInput!
    $condition: ModelUserConditionInput
  ) {
    updateUser(input: $input, condition: $condition) {
      id
      username
      email
      registered
      admin
      orders {
        items {
          id
          UserOrders
          price
          shipped
          paymentIntent
          status
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      createdAt
      updatedAt
      owner
    }
  }
`;
export const createOrder = /* GraphQL */ `
  mutation CreateOrder(
    $input: CreateOrderInput!
    $condition: ModelOrderConditionInput
  ) {
    createOrder(input: $input, condition: $condition) {
      id
      UserOrders
      products {
        items {
          id
          orderMyProduct
          description
          price
          instock
          quantity
          shipped
          owner
          createdAt
          updatedAt
        }
        nextToken
      }
      user {
        id
        username
        email
        registered
        admin
        orders {
          nextToken
        }
        createdAt
        updatedAt
        owner
      }
      price
      shipped
      paymentIntent
      status
      shippingAddress {
        city
        country
        address_line1
        address_state
        address_zip
      }
      createdAt
      updatedAt
      owner
    }
  }
`;
export const updateOrder = /* GraphQL */ `
  mutation UpdateOrder(
    $input: UpdateOrderInput!
    $condition: ModelOrderConditionInput
  ) {
    updateOrder(input: $input, condition: $condition) {
      id
      UserOrders
      products {
        items {
          id
          orderMyProduct
          description
          price
          instock
          quantity
          shipped
          owner
          createdAt
          updatedAt
        }
        nextToken
      }
      user {
        id
        username
        email
        registered
        admin
        orders {
          nextToken
        }
        createdAt
        updatedAt
        owner
      }
      price
      shipped
      paymentIntent
      status
      shippingAddress {
        city
        country
        address_line1
        address_state
        address_zip
      }
      createdAt
      updatedAt
      owner
    }
  }
`;
export const deleteOrder = /* GraphQL */ `
  mutation DeleteOrder(
    $input: DeleteOrderInput!
    $condition: ModelOrderConditionInput
  ) {
    deleteOrder(input: $input, condition: $condition) {
      id
      UserOrders
      products {
        items {
          id
          orderMyProduct
          description
          price
          instock
          quantity
          shipped
          owner
          createdAt
          updatedAt
        }
        nextToken
      }
      user {
        id
        username
        email
        registered
        admin
        orders {
          nextToken
        }
        createdAt
        updatedAt
        owner
      }
      price
      shipped
      paymentIntent
      status
      shippingAddress {
        city
        country
        address_line1
        address_state
        address_zip
      }
      createdAt
      updatedAt
      owner
    }
  }
`;
export const createMyProduct = /* GraphQL */ `
  mutation CreateMyProduct(
    $input: CreateMyProductInput!
    $condition: ModelMyProductConditionInput
  ) {
    createMyProduct(input: $input, condition: $condition) {
      id
      orderMyProduct
      order {
        id
        UserOrders
        products {
          nextToken
        }
        user {
          id
          username
          email
          registered
          admin
          createdAt
          updatedAt
          owner
        }
        price
        shipped
        paymentIntent
        status
        shippingAddress {
          city
          country
          address_line1
          address_state
          address_zip
        }
        createdAt
        updatedAt
        owner
      }
      description
      file {
        bucket
        region
        key
      }
      price
      instock
      quantity
      shipped
      owner
      createdAt
      updatedAt
    }
  }
`;
export const updateMyProduct = /* GraphQL */ `
  mutation UpdateMyProduct(
    $input: UpdateMyProductInput!
    $condition: ModelMyProductConditionInput
  ) {
    updateMyProduct(input: $input, condition: $condition) {
      id
      orderMyProduct
      order {
        id
        UserOrders
        products {
          nextToken
        }
        user {
          id
          username
          email
          registered
          admin
          createdAt
          updatedAt
          owner
        }
        price
        shipped
        paymentIntent
        status
        shippingAddress {
          city
          country
          address_line1
          address_state
          address_zip
        }
        createdAt
        updatedAt
        owner
      }
      description
      file {
        bucket
        region
        key
      }
      price
      instock
      quantity
      shipped
      owner
      createdAt
      updatedAt
    }
  }
`;
export const deleteMyProduct = /* GraphQL */ `
  mutation DeleteMyProduct(
    $input: DeleteMyProductInput!
    $condition: ModelMyProductConditionInput
  ) {
    deleteMyProduct(input: $input, condition: $condition) {
      id
      orderMyProduct
      order {
        id
        UserOrders
        products {
          nextToken
        }
        user {
          id
          username
          email
          registered
          admin
          createdAt
          updatedAt
          owner
        }
        price
        shipped
        paymentIntent
        status
        shippingAddress {
          city
          country
          address_line1
          address_state
          address_zip
          phone_number
        }
        createdAt
        updatedAt
        owner
      }
      description
      file {
        bucket
        region
        key
      }
      price
      instock
      quantity
      shipped
      owner
      createdAt
      updatedAt
    }
  }
`;
