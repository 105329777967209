import React, { useState, useEffect } from 'react';
import { Connect } from 'aws-amplify-react'
import {ByStatusOrders, getUser} from "../graphql/queries"
import { updateOrder,deleteOrder} from "../graphql/mutations"
import { API, graphqlOperation } from 'aws-amplify'

import Error from '../components/Error'
import ProductCard from "../components/OrderProduct"
import {onCreateOrder} from '../graphql/subscriptions'
import { convertCentsToDollar , formatOrderDate } from '../utils'
import {
    Form,
    Dialog,
    Button,
    Card,
    Loading,
    Radio, 
    Notification,
    Message,
    MessageBox,
  } from 'element-react'

  
  

function CustomerOrder({ user, userAttributes }) {
    const [isLoading, setIsLoading] = useState(false);
    const [updateProductDialog, setupdateProductDialog] = useState(false);
    const [shipped, setshipped] = useState(false);
    const [status, setstatus] = useState([]);
    const [statusValues, setStatusValues] = useState([ "Ready for Pickup"]);
    const [nextToken, setNextToken] = useState(undefined)
    const [nextNextToken, setNextNextToken] = useState()
    const [previousTokens, setPreviousTokens] = useState([])
    const [toOrders, setOrders] = useState([])
    const [isAdmin, setAdmin] = useState(false);
    
    const hasNext = !!nextNextToken
    const hasPrev = previousTokens.length
    const limit =3
    let isadmin = false;

    useEffect(() => {
      const fetch = async () => {
        setIsLoading(true)
        try {
          const variables = {
            nextToken,
            limit,
            status: "Processing",
            sortDirection: 'DESC',
          }

          
          const input = { id: userAttributes.sub ,
            nextToken,
            limit,
            sortDirection: 'DESC',
          }

          const resulting = await API.graphql(graphqlOperation(getUser, input))
          if (resulting.data.getUser.admin) {
              const result = await API.graphql(
              graphqlOperation(ByStatusOrders, variables)
            )
            console.log(result)
           isadmin= true;
           setAdmin(true);
          setNextNextToken(result.data.ByStatusOrders.nextToken)
          setOrders(result.data.ByStatusOrders.items)

          } else { 
            console.log(resulting)
            setNextNextToken(resulting.data.getUser.orders.nextToken)
            setOrders( resulting.data.getUser.orders.items)
          } 
        } catch (err) {
          console.log(err)
        } finally {
          setIsLoading(false)
        }
      }

      fetch()
    }, [nextToken])


 
    // Subscribe to creation of Order
    const subscription = API.graphql(
      graphqlOperation(onCreateOrder)
    ).subscribe({
      next: ({ provider, value }) => {
        console.log({ provider, value })
        isAdmin && (Notification({
          title: "Order Notification",
          message: "New Order Received !",
          type: "success",
          duration: 3000,
          onClose: () => {
           window.location = '/profile'
            window.location.reload()
            fetch()

          },
        }));
      },
      error: error => console.warn(error)
    });

   

  function TodoNavigate({ isLoading, hasNext, hasPrev, next, prev }) {
    const disabledPrev = !hasPrev || isLoading
    const disabledNext = !hasNext || isLoading
    return (
      <div className="flex justify-between px-4 py-2 mb-4 text-sm bg-white rounded shadow-md lg:py-4 lg:px-8">
        <button
          className={`${
            disabledPrev
              ? 'bg-orange-500 opacity-50 cursor-not-allowed'
              : 'bg-orange-500 hover:bg-orange-400'
          } shadow focus:shadow-outline focus:outline-none text-white font-bold py-2 px-4 rounded`}
          disabled={disabledPrev}
          onClick={prev}
        >
          <span>Previous</span>
        </button>
        <button
          className={`${
            disabledNext
              ? 'bg-orange-500 opacity-50 cursor-not-allowed'
              : 'bg-orange-500 hover:bg-orange-400'
          } shadow focus:shadow-outline focus:outline-none text-white font-bold py-2 px-4 rounded`}
          disabled={disabledNext}
          onClick={next}
        >
          <span>Next</span>
                  </button>
      </div>
    )
  }

  const next = () => {
    setPreviousTokens((prev) => [...prev, nextToken])
    setNextToken(nextNextToken)
    setNextNextToken(null)
  }

  const prev = () => {
    setNextToken(previousTokens.pop())
    setPreviousTokens([...previousTokens])
    setNextNextToken(null)
  }

  const reset = () => {
    setNextToken(undefined)
    setPreviousTokens([])
    setNextNextToken(null)
  }


  const handleCancelOrder = (Order) => {
    MessageBox.confirm(
      'This will permanently cancel your Order! Continue?',
      'Attention!',
      {
        confirmButtonText: 'Delete',
        cancelButtonText: 'Cancel',
        type: 'warning',
      }
    )
      .then(async () => {
        try {

          console.log("my order ", Order);
          const input = {
            id: Order.id,
            status:"Cancelled"
          }

    
          const result = await API.graphql(graphqlOperation(updateOrder, { input }))
          console.log({ result })
          Notification({
            title: "success",
            message: "Order Canceled  Successfully !",
            type: "success"
          });

         setTimeout(() => window.location.reload(), 2000);

        } catch (err) {
          Message({
            type: 'info',
            message: 'Delete Profile cancelled',
          })
          console.error(`Failed to cancel Order with id: ${Order.id}`, err);

        }


      })
      .catch(() => {
        Message({
          type: 'info',
          message: 'operation aborted',
        })
      })
  }


  const handlePaymentOrder = (Order) => {
    MessageBox.confirm(
      'This will Process Payment  for your Order! Continue?',
      'Attention!',
      {
        confirmButtonText: 'Process Payment',
        cancelButtonText: 'Cancel',
        type: 'warning',
      }
    )
      .then(async () => {
        try {

          console.log("my order ", Order);
          const input = {
            id: Order.id,
            status:"Ready for Pickup"
          }


          const { clientSecret } = await API.post('comlambda', '/cancelcharge', {
            body: {
              charge: {
                amount: Order.price,
                privatekey: Order.paymentIntent,
                is_refund:0,
              }
            }    
          })
          const result = await API.graphql(graphqlOperation(updateOrder, { input }))
          console.log({ result })
        
          Notification({
            title: "success",
            message: "Payment Processed & Order Updated  Successfully !",
            type: "success"
          });
         
          setTimeout(() => window.location.reload(), 2000);

        } catch (err) {
          Message({
            type: 'info',
            message: 'Payment Process  cancelled',
          })
          console.error(`Failed to Payment Process Order with id: ${Order.id}`, err);

        }


      })
      .catch(() => {
        Message({
          type: 'info',
          message: 'operation aborted',
        })
      })
  }



  const handleStatusChange = (e, orderId) => {
    setstatus(e.target.value);
  }


  const handleOrderUp = (OrderId) => {
    MessageBox.confirm(
      'This will update your order status  ! Continue?',
      'Attention!',
      {
        confirmButtonText: 'Update',
        cancelButtonText: 'Cancel',
        type: 'warning',
      }
    )
      .then(async () => {
        try {

          const input = {
            id: OrderId,
            status
          }

          const result = await API.graphql(graphqlOperation(updateOrder, { input }))
          console.log({ result })
          Notification({
            title: "success",
            message: "Order updated Successfully !",
            type: "success"
          });

          setTimeout(() => window.location.reload(), 2000);

        } catch (err) {
          console.error(`Failed to update Order with id: ${OrderId}`, err);

        }

      })
      .catch(() => {
        Message({
          type: 'info',
          message: 'Order Update  cancelled',
        })
      })
  }

  
    


                
  const orders =   toOrders

  return (
   

    <>
      <h2 className="header">
        <img
          src="https://img.icons8.com/color/48/000000/product.png"

          alt="Store Icon" className="large-icon" />
        Orders
      </h2>

      <div className="lg:w">
        <TodoNavigate {...{ hasNext, hasPrev, prev, next, isLoading }} />
      </div>
      {orders.map(order => (


        <div key={order.id} id={order.id} className="my-2">

          <Card
            bodyStyle={{
              padding: "0.7em",
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between'
            }} >

            <div>
              <span className="flex">
                <h6 className="mb-mr">
                  Order ID: {order.id}
                </h6>
              </span>
              <div style={{ color: "var(--lightSquidInk)" }}>
                Number of Item:{order.products && order.products.items.length}
              </div>
              <div style={{ color: "var(--lightSquidInk)" }}>
                Order Total: ${convertCentsToDollar(order.price)}
              </div>
              <div style={{ color: "var(--lightSquidInk)" }}>
                Blocked Amount : ${convertCentsToDollar(order.init_price)}
              </div>
              <div style={{ color: "var(--lightSquidInk)" }}>
                Phone Number : {order.shippingAddress.phone_number}
              </div>
              <h6 className="mb-4">Purchased on: {formatOrderDate(order.createdAt)}</h6>

              <div className="form-group">
                <h6 className="mark mb-4">Status: {order.status}</h6>

              </div>


              {order.shippingAddress && (
                <>
                  <ul className="list-group mb-2">
                    Shipping Address:
                    <li className="list-group-item">
                      <div className="ml-2">
                        <p>{order.shippingAddress.address_line1}</p>
                        <p>
                          {order.shippingAddress.address_zip}{' '}
                          {order.shippingAddress.city},{' '}
                          {order.shippingAddress.state},{' '}
                          {order.shippingAddress.country}
                          {order.shippingAddress.phone_number}
                        </p>

                      </div>
                    </li>
                  </ul>
                </>
              )}
            </div>

          </Card>

         

            <div className="productgrid" >

              {
               order.products &&  order.products.items.map(product => (

                  <ProductCard key={product.id} product={product} order={order} isEdit={isAdmin} />

                ))
              }


            </div>

            { /* Update  Shipping State of the  Order */

              isAdmin &&
              <Card
                bodyStyle={{
                  padding: "0.7em",
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between'
                }} >
                <div className="text-center">
                  <div className="text-center">
                    <Button
                      type="info"
                      icon="edit"
                      size="small"
                      onClick={() => handlePaymentOrder(order)}

                    >
                      Ready for Delivery
                    </Button>

                    <Button
                      type="danger"
                      icon="edit"
                      size="small"
                      onClick={() => handleCancelOrder(order)}
                    >
                      Cancel Order
                    </Button>
                  </div>
                </div>
              </Card>
            }

          
 
        </div>
      )

      )}

    </>
  )






}


export default CustomerOrder;
