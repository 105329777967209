import React from "react";
import { API, graphqlOperation } from "aws-amplify";
import gql from 'graphql-tag';
import { onCreateProduct, onUpdateProduct, onDeleteProduct } from
  "../graphql/subscriptions"
import { Loading, Tabs, Icon } from "element-react";
import { Link } from "react-router-dom"
import NewProduct from "../components/NewProduct"
import Product from "../components/Product"



import MarketList from "../components/MarketList";

export const getMarket = `query GetMarket($id: ID!) {
  getMarket(id: $id) {
    id
    name
    products {
      items {
        id
        description
        price
        shipped
        owner
        file {
          key
        }
        createdAt
      }
      nextToken
    }
    tags
    owner
    createdAt
  }
}
`;



class MarketPage extends React.Component {
  state = {
    market: null,
    isLoading: true,
   
    isMarketowner: false
  };

  componentWillUnmount() {
    // fix Warning: Can't perform a React state update on an unmounted component
    this.setState = (state,callback)=>{
        return;
    };
}
  componentDidMount() {
    this.handleGetMarket();
  }

  componentDidUpdate(prevProps) {
    if (this.props.marketId !== prevProps.marketId) {
        this.handleGetMarket();
    }

  }

  checkMarketOwner = () => {
    const { user } = this.props
    const { market } = this.state;

    if (user) {
      this.setState({ isMarketowner: user.username === market.owner })
    }
  }




  handleGetMarket = async () => {
    const input = {
      id: this.props.marketId
    }

    console.log({ input });
    const result = await API.graphql(graphqlOperation(getMarket, input));
    
    console.log({ result });
    this.setState({ market: result.data.getMarket, isLoading: false }, () => {
      this.checkMarketOwner()
    });
  }

  render() {
    const { market, isLoading, isMarketowner } = this.state;
    return isLoading ? (
      <Loading fullscreen={true} />
    ) : (
        <>
          {/* Back Button */}
          <Link className="link" to="/">
            Back to Sweets  List
          </Link>

          {/* Market Metadata */}

          <span className="items-center pt-2" >
            <h2 className="mb-mr">{market.name} </h2>- {market.owner}
          </span>

          <div className="items-center pt-2">
            <span style={{
              color: 'var(--lightSquidInk)',
              paddingBottom: '1em'
            }} >
              <Icon name="date" className="icon" />
              {market.createdAt}
            </span>
          </div>

          {/* New product */}

          <Tabs type="border-card" value={isMarketowner ? "1" : "2"} >
            {isMarketowner && (
              <Tabs.Pane
                label={
                  <>
                    <Icon name="plus" className="icon>" />
               Add product
                  </>
                }


              >
                <NewProduct marketId={this.props.marketId} />

              </Tabs.Pane>

            )}

            { /* Products List */}
            <Tabs.Pane
              label={
                <>
                  <Icon name="menu" className="icon" />
            Products ( {market.products.items.length})
           </>
              }
              name="2"
            >

              <div className="productgrid">
                {market.products.items.map(product => (
                  <Product key={product.id} product={product} />

                ))}
              </div>
            </Tabs.Pane>

          </Tabs>

        </>

      )

  }
}

export default MarketPage;
