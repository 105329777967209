import React from "react";
import { S3Image } from "aws-amplify-react";
import {Tag } from "element-react";

import { Notification, Popover, Button, Dialog, Card, Form, Input, Radio } from "element-react";
import { convertCentsToDollar, convertDollarsToCents,round2 } from '../utils'
import { API, graphqlOperation } from 'aws-amplify'
import { updateMyProduct, deleteMyProduct, updateOrder} from '../graphql/mutations'
import {getOrder} from '../graphql/queries'
import { UserContext } from '../App'





class OrderProduct extends React.Component {
  state = {
    description: "",
    price: "",
    quantity:"",
    shipped: false,
    updateProductDialog: false,
    deleteProductDialog: false
 
  };

  componentWillUnmount() {
    // fix Warning: Can't perform a React state update on an unmounted component
    this.setState = (state, callback) => {
      return;
    };
  }

  handleOrdePriceUpdate = async (orderId, order) => {

    try {

      const order_result = await API.graphql(graphqlOperation(getOrder, { id: orderId }))
      console.log({ order_result })

      const updated_product = order_result.data.getOrder.products.items;

      console.log({ updated_product })
      let total = updated_product.reduce((total, product) => total + product.price * product.quantity, 0).toFixed(2);
      let tax =round2(0.095 * total);
      var tprice = (parseFloat(total) + parseFloat(tax)).toFixed(0);

      console.log({total })
      const input = {
        id: orderId,
        price: parseFloat(tprice),
        shipped: false,
        status: "Processing",

      }
      
      const order_updated  = await API.graphql(
        graphqlOperation(updateOrder, { input }, {authMode: "AMAZON_COGNITO_USER_POOLS"})
      )

    } catch (err) {
      console.error(`Failed to update Order with id: ${orderId}`, err);

    }
  }

  handleUpdateProduct = async (productId,order) => {
    try {
      console.log({ order })
      this.setState({ updateProductDialog: false })
      const { description, price, shipped, quantity } = this.state
      const input = {
        id: productId,
        description,
        shipped,
        quantity,
        price: convertDollarsToCents(price),

      }

      const result = await API.graphql(graphqlOperation(updateMyProduct, { input }))
      console.log({ result })

      this.handleOrdePriceUpdate(order.id, order)
      Notification({
        title: "success",
        message: "Product Successfully updated in  Order!",
        type: "success"
      });

      setTimeout(() => window.location.reload(), 2000);

    } catch (err) {
      console.error(`Failed to update product with id: ${productId}`, err);

    }

  }

  handleOutOfStockProduct = async (productId,order) => {
    try {
      console.log({ order })
      this.setState({ updateProductDialog: false })
      const { description, price, shipped, quantity } = this.state
      const input = {
        id: productId,
        quantity:0,
        instock:false,
        
      }

      const result = await API.graphql(graphqlOperation(updateMyProduct, { input } ))
      console.log({ result })

      this.handleOrdePriceUpdate(order.id, order)
      Notification({
        title: "success",
        message: "Product Successfully updated in  Order!",
        type: "success"
      });

      setTimeout(() => window.location.reload(), 2000);

    } catch (err) {
      console.error(`Failed to update product with id: ${productId}`, err);

    }

  }


  handleDeleteProduct = async (productId, order)  => {
    try {
      console.log({ order })
      this.setState({ deleteProductDialog: false })
      const input = {
        id: productId

      }
      const result = await API.graphql(graphqlOperation(deleteMyProduct, { input }))
      console.log({ result })

      this.handleOrdePriceUpdate(order.id, order)
      Notification({
        title: "success",
        message: "Product Successfully Removed from Order!!",
        type: "success"
      });

      setTimeout(() => window.location.reload(), 2000);
    } catch (err) {
      console.error(` Failed to delete product with id ${productId}`, err);

    }
  }
  render() {


    const { updateProductDialog, deleteProductDialog, description, price, shipped,quantity} = this.state
    const { product, order, isEdit } = this.props;

    const {isUpdate} = 0;
 
    return (
      <UserContext.Consumer>

        {({ user }) => {
          
          return (
            <div className="card-container">
              <div className="card card-body">

                <S3Image
                  imgKey={product.file.key}
                  theme={{
                    photoImg: {
                      width: '126px', height: '126px',

                    }
                  }}
                />

                <div className="card-body">


                  <p className="text-700 mtopbot5 product-item-name ">
                    {product.description}
                  </p>
                  <div>
                  {product.instock == false && (
                      <Tag key={"out"} type="danger" className="mx-1" >
                        {"out of stock"}
                      </Tag>
                    )}
        
                  </div>
                  <div className="text-right">
                  <span className="mx-1">
                       {product.quantity} items x
                    </span>
                    <span className="mx-1">
                      ${convertCentsToDollar(product.price)}
                    </span>
                    

                  </div>

                </div>

              </div>

              { /* Update / Delete Product */}
              { isEdit  && product.instock == true && (
              <div className="text-center">
                { (
                  <>
                   {/* <Button
                      type="warning"
                      icon="edit"
                      className="m1"
                      onClick={() => this.setState({
                        updateProductDialog: true,
                        description: product.description,
                        shipped: product.shipped,
                        price: convertCentsToDollar(product.price),
                        quantity:product.quantity,
                      })}
                    />
                    */}
                    <Popover
                      placement="top"
                      width="160"
                      trigger="click"
                      visible={deleteProductDialog}
                      content={
                        <>
                          <p> Do you want to remove  this product and mark it as Out of Stock  ?</p>
                          <div className="text-right">
                            <Button
                              size="mini"
                              type="text"
                              className="m-1"
                              onClick={() => this.setState({ deleteProductDialog: false })}
                            >
                              Cancel
                            </Button>
                            <Button
                              type="primary"
                              size="mini"
                              className="m-1"
                              onClick={() => this.handleOutOfStockProduct(product.id,order)}
                            >
                              confirm
                            </Button>
                          </div>
                        </>
                      }
                    >
                      
                      <Button
                        type="danger"
                        icon="delete"
                        onClick={() => this.setState({ deleteProductDialog: true })} />
                    </Popover>

                    
                    
                  </>
                )}

              </div>
              )}

              { /* Update Product Dialog */}
             
              <Dialog
                title="Update Product"
                size="large"
                customClass="dialog"
                visible={updateProductDialog}
                onCancel={() => this.setState({ updateProductDialog: false })}

              >
                <Dialog.Body>
                  <Form>
                    <Form.Item label="Update Description">
                      <Input
                        placeholder="Product escription"
                        value={description}
                        trim={true}
                        onChange={description => this.setState({ description })}
                      />
                    </Form.Item>
                    <Form.Item label="Update Product Price">
                      <Input
                        type="number"
                        icon="minus"
                        placeholder="Price($USD)"
                        value={price}
                        onChange={price => this.setState({ price })}
                      />
                    </Form.Item>
                    <Form.Item label="Update Product quantity">
                      <Input
                        type="number"
                        icon="minus"
                        placeholder="Price($USD)"
                        value={quantity}
                        onChange={quantity => this.setState({ quantity})}
                      />
                    </Form.Item>
                    <Form.Item label="Update shipping">
                      <div className="text-center"> 
                        <Radio
                          value="true"
                          checked={shipped === true}
                          onChange={() => this.setState({ shipped: true })}
                        >
                          Shipped
                        </Radio>
                        <Radio
                          value="false"
                          checked={shipped === false}
                          onChange={() => this.setState({ shipped: false })}
                        >
                          Emailed
                        </Radio>

                      </div>
                    </Form.Item>

                  </Form>
                </Dialog.Body>
                <Dialog.Footer>
                  <Button
                    onClick={() => this.setState({ updateProductDialog: false })}
                  >
                    Cancel
                  </Button>

                  <Button
                    type="primary"
                    onClick={() => this.handleUpdateProduct(product.id, order )}
                  >
                    Update
                  </Button>

                </Dialog.Footer>


              </Dialog>




            </div>
          )

        }}

      </UserContext.Consumer>

    )

  }
}

export default OrderProduct;
