import React from "react";
import { Auth, Hub, API, graphqlOperation, formContainer } from "aws-amplify";
import {getUser } from './graphql/queries'
import {registerUser} from './graphql/mutations'
import { Authenticator } from "aws-amplify-react";
import { Router as Router, Route , Switch } from "react-router-dom";
import {  Redirect} from "react-router-dom";
import createBrowserHistory from 'history/createBrowserHistory'
import {AmplifyTheme} from "aws-amplify-react";
//import '@aws-amplify/ui/dist/style.css';
import HomePage from './pages/HomePage';
import ProfilePage from './pages/ProfilePage';
import MarketPage from './pages/MarketPage';
import GMarkPage from './pages/gmarkpage'
import Navbar from './components/Navbar';
import Home from './components/Home/Home'
import HPayList from './components/HPayList';
import HCart from './components/HCart';
import CartContextProvider from './components/contexts/CartContext';
import Footer from './components/Footer';
import Services from './components/Services'
import StandardImageList from './components/StandardImageList'
import PrivateRoute from './components/auth/PrivateRoute';
import GMarketAltList from './components/GMarketAltList';
import SearchBar from './components/search/SearchBar';
import ScrollToTop from "react-scroll-to-top";
import Box from '@mui/material/Box';

import Club from './components/Club'
import "./App.css";

export const history = createBrowserHistory()
export const UserContext = React.createContext()





class App extends React.Component {
  state = {
    user: null,
    userAttributes: null,
    admin:0,
    userlogin: false
  };
  



  componentDidMount() {
    this.getUserData();
    Hub.listen('auth', this, 'onHubCapsule')
  }

fetchUserAttributes = async authUserData => {
    const attributesArr = await Auth.userAttributes(authUserData)
    const attributesObj = Auth.attributesToObject(attributesArr)
    return attributesObj
  }

  getUserData = async () => {
    const user = await Auth.currentAuthenticatedUser();
    const userAttributes = await this.fetchUserAttributes(user);
    this.setState({ userAttributes:userAttributes })
    const anonymousUser = await Auth.currentCredentials()
      .then(credentials => {
        this.setState({ user: credentials })
       
      })
    console.log(" i am trying to getUserData", {user});
    this.checkadminUser(user)
    user ? this.setState({ user: user }) : this.setState({ user: anonymousUser })
  }

  onHubCapsule = capsule => {
    switch (capsule.payload.event) {
      case "signIn":
        console.log("signedIn");
        this.getUserData();
        this.registerNewUser(capsule.payload.data);
        break;
      case "signUp":
        console.log("signed up");
        break;
      case "signOut":
        console.log("signed out");      
        break;
      default:
        return;
    }
  }

  checkadminUser = async (user) => {
    const getUserInput = {
      id: user.attributes.sub
    };

    const { data } = await API.graphql(graphqlOperation(getUser, getUserInput));

    if (data.getUser) {
      
      console.log("the data is ", { data });
      this.setState({ admin:data.getUser.admin})
    }

  }



  registerNewUser = async (signInData) => {
    const getUserInput = {
      id: signInData.signInUserSession.idToken.payload.sub
    };

    const { data } = await API.graphql(graphqlOperation(getUser, getUserInput));


    if (!data.getUser) {
      try {
        const registerUserInput = {
          ...getUserInput,
          username: signInData.username,
          email: signInData.signInUserSession.idToken.payload.email,
          registered: true,
          admin:0
        };

        const newUser = await API.graphql(
          graphqlOperation(registerUser, { input: registerUserInput })
        );
        console.log({ newUser });
      } catch (error) {
        console.error('Error registering new user', error);
      }
    } else {
     
      this.setState({ admin:data.getUser.admin})
      console.log("setting up the admin here is " ,{data})
    }
  };




  handleSignout = async () => {
    try {
      await Auth.signOut({ global: true })
      this.setState({ user: null, userlogin: false });
    } catch (err) {
      console.error('Error signing out user', err);
    }
  }

  handleSignin = async () => {
    try {
      this.setState({ user: null,userlogin: true });
    } catch (err) {
      console.error('Error signing in user', err);
    }
  }

  render() {

    const { user, userlogin, userAttributes,admin  } = this.state;
    console.log({ user });
    console.log ({userAttributes});
    console.log("admin is " ,{ admin });
    let {uservalid, isadmin} = false; 
    
    if (!user) {
       uservalid = false; 
    } else { 
       if (admin){ 
           isadmin = true; 
       } else {
            isadmin = false;
       }
      uservalid = true;   
    }


    return (!user && userlogin) ? (
      <Box
        sx={{
          width: 500,
          height: 500,

        }}
      >
        <Authenticator theme={theme} />
      </Box>) : !user ? (

        <CartContextProvider>
          
        
        <ScrollToTop smooth color="#6f00ff" />
        <Router history={history}>
       
       
   
          <>
            
            <Club  user={user} handleSignin={this.handleSignin}  
                      handleSignout={this.handleSignout} />
          
            <div className="app-container">
          
            <Switch>
         
              <Route exact path="/" component={GMarketAltList} />
              <Route exact path="/about" component={Services} />
              <Route exact path="/discover" component={StandardImageList} />
              <Route name="profile-page" path="/profile" 
                  component={({ match }) => (
                    <Authenticator theme={theme} />

                  )}
               />
               
              <Route path="/cart" component={HCart} />
              <Route path="/HPayList" 
                  component={({ match }) => (
                    <Authenticator theme={theme} />
                  )}
               />
               
              <Route exact path="/markets/:marketId"
                component ={({ match }) => (
            
                   <GMarkPage  user={user} marketId={match.params.marketId} />
                )}
              />
             </Switch>
            </div>
            <Home/>
            <Footer />
          </>
        </Router>

        </CartContextProvider>
      
      ) 

      : (
        <CartContextProvider>
           <ScrollToTop smooth color="#6f00ff" />
          <UserContext.Provider value={{ user }}>

            <Router history={history}>
              <>

                <Club user={user} handleSignin={this.handleSignin}
                  handleSignout={this.handleSignout} />
           
                          
                <div className="app-container">
       
                  <PrivateRoute uservalid={uservalid}  path="/"
                    isadmin={isadmin }
                    exact component={HomePage}
                  />
                  <Route exact path="/about" component={Services} />
                  <Route exact path="/discover" component={StandardImageList} />
                  <PrivateRoute uservalid={uservalid} path="/profile"
                    user={user}
                    userAttributes={userAttributes}
                    exact component={ProfilePage}
                  />

                  <Route path="/cart" component={HCart} />
                  <PrivateRoute uservalid={uservalid} path="/HPayList" exact component={HPayList} />
                    <Route exact path="/markets/:marketId"
                      component={({ match }) => (
                        isadmin ? (<MarketPage user={user} marketId={match.params.marketId} />) : (
                          <GMarkPage user={user} marketId={match.params.marketId} />)
                         
                      )}
                  />

                </div>
                <Home/>
                <Footer />
              </>
            </Router>
          </UserContext.Provider>
        </CartContextProvider>

        );
      
     
  }
}

const theme = { 
  ...AmplifyTheme,
  navBar: {
    ...AmplifyTheme.navBar,
    backgroundColor: '#ffc0cb'
  },

  button: {
    ...AmplifyTheme.button,
    backgroundColor: '#ffc0cb'
},
Container: {
  ...AmplifyTheme.Container,
  display: 'flex',
  textAlign: 'center'
},
formContainer: {
  ...AmplifyTheme.Container,
  display: 'flex',
  textAlign: 'center'
}

};


export default App;
