import React from "react";
//import { ErrorSection } from "aws-amplify-react/dist/AmplifyUI";

const Error = ({errors}) =>  (
  <pre className="error">
      {errors.map( (err , i) => <div key={i}>{err.message} </div>)} 
  </pre>

)

export default Error;
