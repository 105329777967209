import React from "react";
import { S3Image } from "aws-amplify-react";

import { Notification, Popover, Button, Dialog, Card, Form, Input, Radio } from "element-react";
import { convertCentsToDollar, convertDollarsToCents } from '../utils'
import { API, graphqlOperation } from 'aws-amplify'
import { updateProduct, deleteProduct } from '../graphql/mutations'
import { UserContext } from '../App'




class Product extends React.Component {
  state = {
    description: "",
    price: "",
    shipped: false,
    updateProductDialog: false,
    deleteProductDialog: false
  };

  componentWillUnmount() {
    // fix Warning: Can't perform a React state update on an unmounted component
    this.setState = (state,callback)=>{
        return;
    };
}
  handleUpdateProduct = async productId => {
    try {

      this.setState({ updateProductDialog: false })
      const { description, price, shipped } = this.state
      const input = {
        id: productId,
        description,
        shipped,
        price: convertDollarsToCents(price),

      }

      const result = await API.graphql(graphqlOperation(updateProduct, { input }))
      console.log({ result })
      Notification({
        title: "success",
        message: "Product Successfully updated!",
        type: "success"
      });

      setTimeout(() => window.location.reload(), 2000);

    } catch (err) {
      console.error(`Failed to update product with id: ${productId}`, err);

    }

  }

  handleDeleteProduct = async productId => {
    try {
      this.setState({ deleteProductDialog: false })
      const input = {
        id: productId
   
      }
      const result = await API.graphql(graphqlOperation(deleteProduct, { input }))
      console.log({ result })
      Notification({
        title: "success",
        message: "Product Deleted  Successfully!",
        type: "success"
      });

      setTimeout(() => window.location.reload(), 2000);
    } catch (err) {
      console.error(` Failed to delete product with id ${productId}`, err);

    }
  }
  render() {


    const { updateProductDialog, deleteProductDialog, description, price, shipped } = this.state
    const { product } = this.props;
    
    return (
      <UserContext.Consumer>

        {({ user }) => {
          const isProductOwner = (user && user.attributes.email);
        
          return (
            <div className="card-container">
              <div className="card card-body">
              
                <S3Image
                  imgKey={product.file.key}
                  theme={{
                    photoImg: {
                      width: '126px', height: '126px',

                    }
                  }}
                />

                <div className="card-body">
                 

                  <p className="text-700 mtopbot5 product-item-name ">
                    {product.description}
                  </p>
                
                  <div className="text-right">
                    <span className="mx-1">
                      ${convertCentsToDollar(product.price)}
                    </span>
                   
                  </div>

                </div>

                </div>

              { /* Update / Delete Product */}

              <div className="text-center">
                {isProductOwner && (
                  <>
                    <Button
                      type="warning"
                      icon="edit"
                      className="m1"
                      onClick={() => this.setState({
                        updateProductDialog: true,
                        description: product.description,
                        shipped: product.shipped,
                        price: convertCentsToDollar(product.price)
                      })}
                    />

                    <Popover
                      placement="top"
                      width="160"
                      trigger="click"
                      visible={deleteProductDialog}
                      content={
                        <>
                          <p> Do you want to delete this ?</p>
                          <div className="text-right">
                            <Button
                              size="mini"
                              type="text"
                              className="m-1"
                              onClick={() => this.setState({ deleteProductDialog: false })}
                            >
                              Cancel
                         </Button>
                            <Button
                              type="primary"
                              size="mini"
                              className="m-1"
                              onClick={() => this.handleDeleteProduct(product.id)}
                            >
                              confirm
                         </Button>
                          </div>
                        </>
                      }
                    >
                      <Button
                        type="danger"
                        icon="delete"
                        onClick={() => this.setState({ deleteProductDialog: true })} />
                    </Popover>

                  </>
                )}

              </div>

              { /* Update Product Dialog */}
              <Dialog
                title="Update Product"
                size="large"
                customClass="dialog"
                visible={updateProductDialog}
                onCancel={() => this.setState({ updateProductDialog: false })}

              >
                <Dialog.Body>
                  <Form>
                    <Form.Item label="Update Description">
                      <Input
                        placeholder="Product escription"
                        value={description}
                        trim={true}
                        onChange={description => this.setState({ description })}
                      />
                    </Form.Item>
                    <Form.Item label="Update Product Price">
                      <Input
                        type="number"
                        icon="plus"
                        placeholder="Price($USD)"
                        value={price}
                        onChange={price => this.setState({ price })}
                      />
                    </Form.Item>
                    <Form.Item label="Update shipping">
                      <div className="text-center">
                        <Radio
                          value="true"
                          checked={shipped === true}
                          onChange={() => this.setState({ shipped: true })}
                        >
                          Shipped
                      </Radio>
                        <Radio
                          value="false"
                          checked={shipped === false}
                          onChange={() => this.setState({ shipped: false })}
                        >
                          Emailed
                      </Radio>

                      </div>
                    </Form.Item>

                  </Form>
                </Dialog.Body>
                <Dialog.Footer>
                  <Button
                    onClick={() => this.setState({ updateProductDialog: false })}
                  >
                    Cancel
                 </Button>

                  <Button
                    type="primary"
                    onClick={() => this.handleUpdateProduct(product.id)}
                  >
                    Update
                 </Button>

                </Dialog.Footer>


              </Dialog>




            </div>
          )

        }}

      </UserContext.Consumer>

    )

  }
}

export default Product;
