import React, { useState,useRef,useEffect } from 'react';
import Navbar from '../Navbar';
import Sidebar from '../Sidebar';


function Club({ user,  handleSignout, handleSignin}) {
  const [isOpen, setIsOpen] = useState(false);
  


  const toggle = () => {
   
      setIsOpen(!isOpen);
    
  };


  return (
    <>
      <Sidebar isOpen={isOpen} toggle={toggle} user={user} handleSignin={handleSignin}  
            handleSignout={handleSignout} />
      <Navbar toggle={toggle} user={user} handleSignin={handleSignin}  
                      handleSignout={handleSignout} />
      
    </>
  );
}

export default Club;
