import React from "react";
import { graphqlOperation } from 'aws-amplify'
import { Connect } from 'aws-amplify-react'
import { listMarkets } from '../graphql/queries'
import { onCreateMarket } from '../graphql/subscriptions'
import { Loading, Card, Tag } from "element-react";
import MyCard from "../components/MyCard"
import ProductCard from "../components/ProductItem"
import { Link } from 'react-router-dom'
import Error from './Error'
import Product from "../components/Product"
import { Link as LinkS } from 'react-scroll';
import styled from 'styled-components';


export const GbarLink = styled(LinkS)`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1rem;
  text-decoration: none;
  list-style: none;
  transition: 0.2s ease-in-out;
  text-decoration: none;
  color: #fff;
  cursor: pointer;

  &:hover {
    color: #01bf71;
    transition: 0.2s ease-in-out;
  }
`;



const MarketList = ({ searchResults }) => {



  const onNewMarket = (prevQuery, newData) => {
    let updatedQuery = { ...prevQuery };

    const updatedMarketList = [
      newData.onCreateMarket,
      ...prevQuery.listMarkets.items
    ];
    updatedQuery.listMarkets.items = updatedMarketList;
    return updatedQuery;
  }



  return (
    // Simple query

    <Connect
      query={graphqlOperation(listMarkets)}
      subscription={graphqlOperation(onCreateMarket)}
      onSubscriptionMsg={onNewMarket}
    >

      {({ data, loading, errors }) => {
        console.log("data", data)

        if (errors && errors.length > 0) return <Error errors={errors} />
        if (loading || !data || !data.listMarkets) return <Loading fullscreen={true} />
        const markets = searchResults.length > 0 ? searchResults : data.listMarkets.items

        return (
          <>
            <h2 className="header">
              <img
                src="https://img.icons8.com/color/48/000000/product.png"

                alt="Store Icon" className="large-icon" />
                Markets
            </h2>
            <div className="my-2">
              {markets.map(market => (


                <GbarLink to={`${market.name}`}
                  key={market.id}
                  smooth={true}
                  duration={500}
                  spy={true}
                  exact='true'
                  offset={-80}
                  className="bg-primary text-white display-inline-block rounded-corners-big
                  item-price pleftright" >
                  {market.name}
                </GbarLink>


              ))
              }
            </div>
            
            {markets.map(market => (

              <div key={market.id} id={market.name} className="my-2">
                <Card
                  bodyStyle={{
                    padding: "0.7em",
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between'
                  }} >

                  <div>
                    <span className="flex">
                      <Link className="link" to={`/markets/${market.id}`} >
                        {market.name}
                      </Link>
                      <span style={{ color: 'var(--darkAmazonOrange)' }}>
                        {market.products.items && market.products.items.length}
                      </span>

                      <img src="https://img.icons8.com/color/48/000000/shopping-cart-loaded.png"
                        alt="Shopping Cart" />
                    </span>

                

                  </div>
                  <div>
                    {market.tags && market.tags.map(tag => (
                      <Tag key={tag} type="danger" className="mx-1" >
                        {tag}
                      </Tag>
                    ))}
                  </div>

                </Card>

                <div >
                  <div className="row">
                    <div className="col-sm-8">
                      <div className="py-3">
                        {market.products.length}
                    </div>
                    </div>
                  </div>
                  <div className="productgrid" >

                    {
                     market.products.items && market.products.items.map(product => (
                        
                        <ProductCard key={product.id} product={product} />
                      ))
                    }

                    <div className="card card-body">
                      <div className="no-border product-view-all-btn">

                        <div className="cursor-pointer">
                          
                               <Link className="mtopbot10 btn btn-tertiary text-x-lg pad20" to={`/markets/${market.id}`} >
                               View ALL
                               </Link>
                        </div>

                      </div>
                    </div>

                  </div>
                </div>

              </div>
            )

            )}
          </>
        )

      }}

    </Connect>

  )
};

export default MarketList;
