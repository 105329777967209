import format from 'date-fns/format'
import {AmplifyTheme} from "aws-amplify-react";

export const convertDollarsToCents = price => (price*100).toFixed(0) 
export const convertCentsToDollar = price => (price/100).toFixed(2)
export const round2 = (num) => Math.round(num * 100 + Number.EPSILON) / 100; // 123.2345 => 123.23

export const  convertPricetoNum = price => {
    if (Number.isNaN(Number.parseFloat(price))) {
      return 0;
    }
    return parseFloat(price);
  }
export const formatNumber = number => {
    return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(convertCentsToDollar (number));
}

export const formatProductDate = date => format(date, 'MMM Do, YYYY')
export const formatOrderDate = date => format(date, 'ddd h:mm A, MMM Do, YYYY')


export const isAuthenticated = (user ) => {
  if (!user ) {
      return false;
  } else { 
    return true;  
  }
};

export const theme = { 
  ...AmplifyTheme,
  navBar: {
    ...AmplifyTheme.navBar,
    backgroundColor: '#ffc0cb'
  },

  button: {
    ...AmplifyTheme.button,
    backgroundColor: '#ffc0cb'
}

};