import React, { useContext } from 'react';
import { PlusCircleIcon, MinusCircleIcon, TrashIcon } from './Icon'
import { CartContext } from './contexts/CartContext';
import { convertCentsToDollar, convertDollarsToCents } from '../utils'
import { formatNumber } from  '../utils';
import { Notification} from 'element-react'

import config from '../aws-exports'

const {
    aws_user_files_s3_bucket_region: region,
    aws_user_files_s3_bucket: bucket
} = config


const CartItem = ({product}) => {

    const { increase, decrease, removeProduct } = useContext(CartContext);
    const url = `https://${bucket}.s3.${region}.amazonaws.com/public/${product.file.key}`

    return ( 
        <div> 
        <div className="row no-gutters py-2">
            <div className="col-sm-2 p-2">
                <img
                alt={product.name}
                style={{margin: "0 auto", maxHeight: "50px"}} 
                src={url} className="img-fluid d-block"/>
            </div>
            <div className="col-sm-4 p-2">
                <h5 className="mb-1">{product.name}</h5>
                <p className="mb-1">Price: {convertCentsToDollar(product.price)} </p>
                
            </div>
            <div className="col-sm-2 p-2 text-center ">
                 <p className="mb-0">Qty: {product.quantity}</p>
            </div>
            <div className="col-sm-4 p-2 text-right">
                 <button 
                 onClick={() =>{ increase(product) 
                                    Notification({
                                        title: "success",
                                        message: "Added to cart!",
                                        type: "success"
                                    });
                
                                }}
                 className="btn btn-primary btn-sm mr-2 mb-1">
                     <PlusCircleIcon width={"20px"}/>
                 </button>

                 {
                     product.quantity > 1 &&
                     <button
                    onClick={() => { decrease(product) 
                                        Notification({
                                            title: "success",
                                            message: "Removed from cart!",
                                            type: "success"
                                        });

                                    }}
                    className="btn btn-danger btn-sm mb-1">
                        <MinusCircleIcon width={"20px"}/>
                    </button>
                 }

                {
                     product.quantity === 1 &&
                     <button
                    onClick={() => {removeProduct(product) 
                                        Notification({
                                            title: "success",
                                            message:"delete from cart!",
                                            type: "success"
                                        });

                                    }} 
                    className="btn btn-danger btn-sm mb-1">
                        <TrashIcon width={"20px"}/>
                    </button>
                 }
                 
            </div>
           
        </div>
        <hr className="my-4"/>
        </div>
     );
}
 
export default CartItem;