import React, { useState, useContext} from "react";
import { getUser } from '../graphql/queries'
import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";
import styled from "@emotion/styled";
import { API , graphqlOperation} from 'aws-amplify'
import { history } from '../App'
import { createOrder, createMyProduct} from '../graphql/mutations'

import { Notification, Message } from 'element-react'
import Row from "./prebuilt/Row";
import BillingDetailsFields from "./prebuilt/BillingDetailsFields";
import SubmitButton from "./prebuilt/SubmitButton";
import CheckoutError from "./prebuilt/CheckoutError";
import { formatNumber } from '../utils';
import { UserContext } from '../App'
import { CartContext } from './contexts/CartContext';
import aws_exports from '../aws-exports'


export const FContainer = styled.div`
  min-height: 692px;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
  z-index: 0;
  overflow: hidden;
  background: linear-gradient(
    108deg,
    rgba(1, 147, 86, 1) 0%,
    rgba(10, 201, 122, 1) 100%
  );
`;

export const FormWrap = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;

  @media screen and (max-width: 400px) {
    height: 80%;
  }
`;

export const FormContent = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;

  @media screen and (max-width: 480px) {
    padding: 10px;
  }
`;

export const Form = styled.form`
  background: #c4d4cb;
  max-width: 400px;
  height: auto;
  width: 100%;
  z-index: 1;
  display: grid;
  margin: 0 auto;
  padding: 80px 32px;
  border-radius: 4px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.9);

  @media screen and (max-width: 400px) {
    padding: 32px 32px;
  }
`;



const CardElementContainer = styled.div`
  height: 40px;
  display: flex;
  align-items: center;

  & .StripeElement {
    width: 100%;
    padding: 15px;
  }
`;



const CheckoutForm = ({ price, onSuccessfulCheckout,  total,tax  }) => {
  const [isProcessing, setProcessingTo] = useState(false);
  const [checkoutError, setCheckoutError] = useState();
  const { user } = useContext(UserContext)
  const {cartItems} = useContext(CartContext);
  const stripe = useStripe();
  const elements = useElements();

  // TIP
  // use the cardElements onChange prop to add a handler
  // for setting any errors:

  const handleCardDetailsChange = ev => {
    ev.error ? setCheckoutError(ev.error.message) : setCheckoutError();
  };

  const getOwnerEmail = async ownerId => {
    try {
      const input = { id: ownerId }
      const result = await API.graphql(graphqlOperation(getUser, input))
      return result.data.getUser.email
    } catch (error) {
      console.error(`Error fetching product owner's email: `, error)
    }
  };
  
  const sendOwnerEmail = async (price,billingDetails) => {
    const { emailresult } = await API.post('comlambda', '/sendemail', {
      body: {
        charge: {
          amount: parseFloat(price),
          billingDetails: billingDetails,
        }
      }
    });
    console.log("emailresult", emailresult);
  };

  const handleFormSubmit = async ev => {
    ev.preventDefault();

    const billingDetails = {
      name: ev.target.name.value,
      email: ev.target.email.value,
      address: {
        city: ev.target.city.value,
        line1: ev.target.address.value,
        state: ev.target.state.value,
        postal_code: ev.target.zip.value
      }
    };

    const createShippingAddress = {
      city: ev.target.city.value,
      country: "usa",
      address_line1: ev.target.address.value,
      address_zip:  ev.target.state.value,
      address_state: ev.target.zip.value,
      phone_number: ev.target.tel.value,
    };
  

    setProcessingTo(true);

    

    const cardElement = elements.getElement("card");
    const ownerEmail  = 'rselvam@gmail.com'
    try {

      const { clientSecret } = await API.post('comlambda', '/charge', {
        body: {
          charge: {
            amount: parseFloat(price),
          }
        }
      })

      console.log("clientSecret", clientSecret);

      const paymentMethodReq = await stripe.createPaymentMethod({
        type: "card",
        card: cardElement,
        billing_details: billingDetails
      });

      if (paymentMethodReq.error) {
        console.log("paymentMethodReq Error", paymentMethodReq.error.message);
        setCheckoutError(paymentMethodReq.error.message);
        setProcessingTo(false);
        return;
      }

      const { paymentIntent, error } = await stripe.confirmCardPayment(clientSecret, {
        payment_method: paymentMethodReq.paymentMethod.id
      });
      console.log("paymentIntent", paymentIntent);
      

      if (error) {
        console.log("error.message", error.message);
        setCheckoutError(error.message);
        setProcessingTo(false);
        return;
      }
    
      
      sendOwnerEmail (price, billingDetails );
      console.log("user", user);

      const input = {
      
          UserOrders: user.attributes.sub,
          price: parseFloat(price),
          shipped:false,
          tax_price:parseFloat(tax), 
          shipping_price:parseFloat(price), 
          init_price:parseFloat(price), 
          status:"Processing",
          paymentIntent:paymentIntent.id,
          shippingAddress:createShippingAddress,
      }

      const order = await API.graphql(
        graphqlOperation(createOrder, { input })
      )


      console.log({ order })
      cartItems.map(async product => {
        console.log("product", product);

        const file = {
          key: product.file.key,
          bucket: aws_exports.aws_user_files_s3_bucket,
          region: aws_exports.aws_project_region
        }
  
        const input = {
          file,
          orderMyProduct: order.data.createOrder.id,
          description: product.description,
          price: product.price,
          quantity: product.quantity,
          shipped: product.shipped,
          instock:true,
          owner: product.owner,
          
        }

        console.log("product_input", input);
        const productOrder = await API.graphql(
          graphqlOperation(createMyProduct, { input })
        )

      })
      


      Notification({
        title: 'Success',
        message: `Order placed Successfully`,
        type: 'success',
        duration: 3000,
        onClose: () => {
          history.push('/')
          Message({
            type: 'info',
            message: 'Check your email for details.',
            duration: 5000,
            showClose: true,
          })
        },
      })

     onSuccessfulCheckout();
    } catch (err) {
      console.log("error.message hi", err.message);
      setCheckoutError(err.message);
    }
  };

  // Learning
  // A common ask/bug that users run into is:
  // How do you change the color of the card element input text?
  // How do you change the font-size of the card element input text?
  // How do you change the placeholder color?
  // The answer to all of the above is to use the `style` option.
  // It's common to hear users confused why the card element appears impervious
  // to all their styles. No matter what classes they add to the parent element
  // nothing within the card element seems to change. The reason for this is that
  // the card element is housed within an iframe and:
  // > styles do not cascade from a parent window down into its iframes

  const iframeStyles = {
    base: {
      color: "#a2d28a",
      fontSize: "16px",
      iconColor: "#F99A52",
      "::placeholder": {
        color: "#87bbfd"
      }
    },
    invalid: {
      iconColor: "#FFC7EE",
      color: "#E25950"
    },
    complete: {
      iconColor: "#cbf4c9"
    }
  };

  const cardElementOpts = {
    iconStyle: "solid",
    style: iframeStyles,
    hidePostalCode: true
  };

  return (

    <FormWrap>
    <FormContent>
    <Form onSubmit={handleFormSubmit}>
      <Row>
        <BillingDetailsFields />
      </Row>
      <Row>
        <CardElementContainer>
          <CardElement
            options={cardElementOpts}
            onChange={handleCardDetailsChange}
          />
        </CardElementContainer>
      </Row>
      {checkoutError && <CheckoutError>{checkoutError}</CheckoutError>}
      <Row>
        {/* TIP always disable your submit button while processing payments */}
        <SubmitButton disabled={isProcessing || !stripe}>
          {isProcessing ? "Processing..." : `Pay ${formatNumber(price)}`}
        </SubmitButton>
      </Row>
    </Form>
    </FormContent>
    </FormWrap>


  );
};

export default CheckoutForm;
