export const FETCH_DATA_INIT = 'FETCH_DATA_INIT'
export const FETCH_DATA_SUCCESS = 'FETCH_DATA_SUCCESS'
export const FETCH_DATA_FAILURE = 'FETCH_DATA_FAILURE'
export const FETCH_USER_ATTRIBUTES = 'FETCH_USER_ATTRIBUTES'
export const RESET_USER_DATA = 'RESET_USER_DATA'
export const FETCH_PRODUCTS = 'FETCH_PRODUCTS'

export const benefits = [
    {
      icon: 'fal fa-shopping-cart',
      title: 'Free Shipping',
      text: 'Free Shipping on all orders over $50!'
    },
    {
      icon: 'fal fa-credit-card',
      title: 'Secure Payment',
      text: "Shop with Safety and Security"
    },
    {
      icon: 'fal fa-wallet',
      title: '100% Satisfaction',
      text: '30 day money back guarantee'
    },
    {
      icon: 'fal fa-comment',
      title: 'Customer Service',
      text: 'Reliable cusomer servie'
    }
  ]
  