import * as React from "react";
import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";
import styled from 'styled-components';
import config from '../../aws-exports'

const {
  aws_user_files_s3_bucket_region: region,
  aws_user_files_s3_bucket: bucket
} = config

export const StandardImageListContainer = styled.div`
  height: 800px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;


  @media screen and (max-width: 768px) {
    height: 1100px;
  }

  @media screen and (max-width: 480px) {
    height: 1300px;
  }
`;

export const StandardImageListWrapper = styled.div`
  max-width: 1000px;
  margin: 0 auto;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  align-items: center;
  grid-gap: 16px;
  padding: 0 50px;

  @media screen and (max-width: 1000px) {
    grid-template-columns: 1fr 1fr;
  }

  @media screen and (max-width: 768px) {
    grid-template-columns: 1fr;
    padding: 0 20px;
  }
`;
export default function StandardImageList() {
  const test0 = "/public/us-east-1%3A6674e6cd-0af1-4444-abae-58974017d944/public/StoreFron0.jpeg"
  const test = "/public/us-east-1%3A6674e6cd-0af1-4444-abae-58974017d944/public/StoreFront1.jpeg"
  const test1 = "/public/us-east-1%3A6674e6cd-0af1-4444-abae-58974017d944/public/StoreFront2.jpeg"
  const test2 = "/public/us-east-1%3A6674e6cd-0af1-4444-abae-58974017d944/public/StoreFront3.jpeg"
  const test3 = "/public/us-east-1%3A6674e6cd-0af1-4444-abae-58974017d944/public/StoreFron4.jpeg"
  const test4 = "/public/us-east-1%3A6674e6cd-0af1-4444-abae-58974017d944/public/StoreFron5.jpeg"
  const test5 = "/public/us-east-1%3A6674e6cd-0af1-4444-abae-58974017d944/public/StoreFron6.jpeg"
  const test6 = "/public/us-east-1%3A6674e6cd-0af1-4444-abae-58974017d944/public/StoreFron7.jpeg"
  const test7 = "/public/us-east-1%3A6674e6cd-0af1-4444-abae-58974017d944/public/StoreFron8.jpeg"
  

  const url1 = `https://${bucket}.s3.${region}.amazonaws.com/public/${test}`
  const url2 = `https://${bucket}.s3.${region}.amazonaws.com/public/${test1}`
  const url3 = `https://${bucket}.s3.${region}.amazonaws.com/public/${test2}`
  const url4 = `https://${bucket}.s3.${region}.amazonaws.com/public/${test3}`
  const url5 = `https://${bucket}.s3.${region}.amazonaws.com/public/${test4}`
  const url6 = `https://${bucket}.s3.${region}.amazonaws.com/public/${test5}`
  const url7 = `https://${bucket}.s3.${region}.amazonaws.com/public/${test6}`
  const url8 = `https://${bucket}.s3.${region}.amazonaws.com/public/${test7}`
  const url0 = `https://${bucket}.s3.${region}.amazonaws.com/public/${test0}`
  
  const itemData = [
   
    {
      img: url5,
      title: "Burger"
    },
    {
      img: url3,
      title: "Camera"
    },
    {
      img: url4,
      title: "Coffee"
    },
    {
      img: url1,
      title: "Hats"
    },
    {
      img: url6,
      title: "Honey"
    },
    {
      img: url7,
      title: "Basketball"
    },
    {
      img: url8,
      title: "Fern"
    },
    {
      img: url2,
      title: "Mushrooms"
    }
  ];
  
  return (
    <StandardImageListContainer>
      <StandardImageListWrapper>

    <ImageList sx={{ width: 750, height: 650 }} cols={2} rowHeight={364}>
      {itemData.map((item) => (
        <ImageListItem key={item.img}>
          <img
            src={`${item.img}?w=164&h=164&fit=crop&auto=format`}
            srcSet={`${item.img}?w=164&h=164&fit=crop&auto=format&dpr=2 2x`}
            alt={item.title}
            loading="lazy"
          />
        </ImageListItem>
      ))}
    </ImageList>
    </StandardImageListWrapper>
    </StandardImageListContainer>
  );
}

