
import React, { Component,useContext } from "react";
import { Route, Redirect } from "react-router-dom";
import { Authenticator } from "aws-amplify-react";
import { theme } from  '../../utils';


const PrivateRoute = ({ component: Component, uservalid, ...rest }) => (

    <Route
        {...rest}
        render={
            props => uservalid ? (
                <Component {...rest} />
            ) : (
                <Authenticator theme={theme} />
            )
        }
    />

);

export default PrivateRoute;
