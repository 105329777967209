import React, { useState, useEffect,useContext  } from 'react';
import { FaBars } from 'react-icons/fa';
import { FaShoppingCart } from 'react-icons/fa';
import { IconContext } from 'react-icons/lib';
import { animateScroll as scroll } from 'react-scroll';
import {  Button,Icon } from "element-react";
import {  CartIcon } from '../Icon'
import {  CartContext } from '../contexts/CartContext'


import {
  MobileIcon,
  Nav,
  NavbarContainer,
  NavLinktest,
  NavItem,
  NavLinks,
  NavLogo,
  NavMenu,
  NavBtn,
  NavBtnLink
} from './NavbarElements';

const Navbar = ({ toggle ,user,  handleSignout, handleSignin }) => {
  const [scrollNav, setScrollNav] = useState(false);
  const {itemCount} = useContext(CartContext);

  const changeNav = () => {
    if (window.scrollY >= 80) {
      setScrollNav(true);
    } else {
      setScrollNav(false);
    }
  };

  useEffect(() => {

    let isMounted = true;
    if (isMounted) {
      window.addEventListener('scroll', changeNav);
    }
    return () => {
      isMounted = false
    }
  }, []);

  const toggleHome = () => {
    scroll.scrollToTop();
  };

  return (
    <>
      <IconContext.Provider value={{ color: '#fff' }}>
        <Nav scrollNav={scrollNav}>
          <NavbarContainer>
            <NavLogo onClick={toggleHome} to='/'>
              <span className="app-title">
                Bombay Trading Co
              </span>
            </NavLogo>
            
         
            <MobileIcon onClick={toggle}>
              <FaBars />
            
            </MobileIcon>
           
            <NavMenu>
              <NavItem>
              <NavLinktest to="/about" >
                   
                         About
                </NavLinktest >
                
              </NavItem>
              <NavItem>
              <NavLinktest to="/Discover" >
                   
                   Discover
          </NavLinktest >
                
              </NavItem>
              <NavItem>
                <NavLinktest to="/profile" >
                    <Icon name="setting" />
                         Profile
                </NavLinktest >
              </NavItem>
              <NavItem>
               <NavLinktest  onClick={toggleHome} to="/cart" >
                   <FaShoppingCart/>
                    Cart({itemCount})
                 </NavLinktest >
                
              </NavItem>
            </NavMenu>
            {!user ? (
            <NavBtn>
              <Button type="warning"   onClick={handleSignin} >Sign In</Button>
            </NavBtn>) :
            (
            <NavBtn>
              <Button type="warning" onClick={handleSignout} >Sign Out</Button>
            </NavBtn>

            ) } 
          </NavbarContainer>
        </Nav>
      </IconContext.Provider>
    </>
  );
};

export default Navbar;
