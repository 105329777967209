import React, { useState } from "react";
import { S3Image } from "aws-amplify-react";
import { Link } from "react-router-dom"

import { Notification, Popover, Button, Dialog, Card, Form, Input, Radio } from "element-react";
import { convertCentsToDollar, convertDollarsToCents } from '../utils'
import { API, graphqlOperation } from 'aws-amplify'
import { updateProduct, deleteProduct } from '../graphql/mutations'
import { addItem } from './cartHelpers'
import { UserContext } from '../App'

import config from '../aws-exports'

const {
    aws_user_files_s3_bucket_region: region,
    aws_user_files_s3_bucket: bucket
} = config


class MyCard extends React.Component {
    state = {};
    //{redirect,setRedirect} = useState(false)

    handleAddTocart = (product) => {

        console.log(product);
        addItem(product, "");
    };

    render() {
        const { product } = this.props;
        const url = `https://${bucket}.s3.${region}.amazonaws.com/public/${product.file.key}`

        return (
            <div  >
                <div className="card "  
                 style={{ width: "16rem", height:"20rem"}}
                >
                    
                    <div className="card-body">
                    <img
                        class="img-fluid img-thumbnail"
                        style={{ display: "block", margin: "0 auto 5px", maxHeight: "150px" }}
                        src={url}
                    />
                        <div className="text-center">
                            <h5 className="card-title">
                                {product.description}
                            </h5>

                            <span className="card-text">
                                <p>${convertCentsToDollar(product.price)}</p>
                            </span>
                        </div>
                        <div className="text-right">
                        <Link  to="/" className="btn btn-link btn-sm mr-2">Details</Link>
                            <button
                                className="btn btn-primary btn-sm"
                                onClick={() => this.handleAddTocart(product)}>
                                Add to cart
                            </button>
                        </div>
                    </div>
                </div>

            </div>
        )

    }
}

export default MyCard;

