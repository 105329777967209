import React from 'react';


//import { Helmet } from 'react-helmet-async';



const HLayout = ({title, description, children}) => {
    return ( 
        <>
        
        <main className="container">
            {children}
        </main>
 
        </>
     );
}
 
export default HLayout;