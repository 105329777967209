import React from "react";
import { API, graphqlOperation } from 'aws-amplify'
import { listMarkets } from '../graphql/queries'
import { Loading, Card, Icon, Tag, Tabs } from "element-react";
import MyCard from "../components/ProductItem"
import { Link } from 'react-router-dom'
import Error from './Error'
import Product from "../components/Product"
import client from "../App"
import styled from 'styled-components';
import { Link as LinkS } from 'react-scroll';
import Stack from '@mui/material/Stack';
import AppBar from '@mui/material/AppBar';
import SearchBar from "./search/SearchBar";
import Carousel from '../components/Carousel/Carousel'
  
export const GNav = styled.nav`
  background: #e9e9ec;
  height: 80px;
  margin-top: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1rem;
  position: sticky;
  top: 80px;
  z-index: 10;

  @media screen and (max-width: 960px) {
    transition: 0.8s all ease;
  }
`;

export const GNavbarContainer = styled.div`
  display: flex;
  justify-content: space-between;
  height: 80px;
  z-index: 1;
  width: 100%;
  padding: 0 24px;
  max-width: 1300px;
`;

export const GbarLink = styled(LinkS)`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1rem;
  text-decoration: none;
  font-weight: 400;
  list-style: none;
  transition: 0.2s ease-in-out;
  color: #fff;
  cursor: pointer;

  background: #01bf71;
  white-space: nowrap;
  padding: 6px 12px;
  margin: 8px 8px;
  width:150px;

  &:hover {
    color: #01bf71;
    transition: 0.2s ease-in-out;
  }
`;


class GMarketAltList extends React.Component {
    Mounted = false;

    state = {
        markets: null,
        isLoading: true,
    };


    componentDidMount() {
        this.Mounted = true;
        this.handleGetMarketList()
    }

    handleGetMarketList = async () => {
        try {
         
            const result = await API.graphql( {
                      query: listMarkets, 
                      authMode: "API_KEY"
              } );
    
              
            
            console.log(result.data);
            if (this.Mounted) {
                this.setState({ markets: result.data.listMarkets.items, isLoading: false });
            }
        } catch (err) {
            console.error('Error fetching listmarkets', err);
        }

    }

    componentWillUnmount() {
        this.Mounted = false;
    }

    render() {
        const { markets, isLoading } = this.state;
        console.log(markets);


        return isLoading ? (
            <Loading fullscreen={true} />
        ) : (
            <>
            <div class="gbox">
                <Carousel />
            </div>

                <h2 className="header">
                    <img
                        src="https://img.icons8.com/color/48/000000/product.png"
                        alt="Store Icon" className="large-icon" />
                    Markets
                    </h2>

            
                    <GNav >
                        <GNavbarContainer>
                            <Stack direction="row" 
                            spacing={0.5} style={{ maxWidth: 1200, maxHeight: 100, overflow: 'auto', }}>
                            

                                {markets.map(market => (
                                    <button>
                                        <GbarLink to={`${market.name}`}
                                            key={market.id}
                                            smooth={true}
                                            duration={500}
                                            spy={true}
                                            exact='true'
                                            offset={-80}
                                            className="bg-primary text-white display-inline-block rounded-corners-big
                                                  item-price pleftright" >
                                            {market.name}
                                        </GbarLink>
                                    </button>

                                ))
                                }

                            </Stack>

                        </GNavbarContainer>
                    </GNav>
                {markets.map(market => (

                    <div key={market.id} id={market.name} className="my-2">
                        <Card
                            bodyStyle={{
                                padding: "0.7em",
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'space-between'
                            }}
                        >

                            <div>
                                <span className="flex">
                                    <Link className="link" to={`/markets/${market.id}`}  key ={market.id} >
                                        {market.name}
                                    </Link>
                                    <span style={{ color: 'var(--darkAmazonOrange)' }}>
                                        {market.products.items && market.products.items.length}
                                    </span>

                                    <img src="https://img.icons8.com/color/48/000000/shopping-cart-loaded.png"
                                        alt="Shopping Cart" />
                                </span>

                     
                            </div>
                            <div>
                                {market.tags && market.tags.map(tag => (
                                    <Tag key={tag} type="danger" className="mx-1" >
                                        {tag}
                                    </Tag>
                                ))}
                            </div>

                        </Card>


                        <div className="productgrid" >
                            {market.products.items && market.products.items.map(product => (
                                  <MyCard key={product.id} product={product} />

                            ))}


                            <div className="card card-body">
                                <div className="no-border product-view-all-btn">

                                    <div className="cursor-pointer">
                                        
                                                  <Link className="mtopbot10 btn btn-tertiary text-x-lg pad20" to={`/markets/${market.id}`} >
                                                     View ALL
                                                 </Link>
                                        

                                      
                                    </div>

                                </div>
                            </div>
                        </div>

                    </div>
                ))}
                
            </>

        )
    }
}

export default GMarketAltList;
