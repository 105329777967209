import styled from "@emotion/styled";
import React from "react";
const Row = styled.div`
  width: 300px;
  margin: 5px auto;
  
  box-shadow: 0 6px 9px rgba(50, 50, 93, 0.06), 0 2px 5px rgba(0, 0, 0, 0.08),
    inset 0 1px 0 #829fff;
  border-radius: 4px;
  background-color: #324157;
  position: relative;
  
`;

export default Row;
