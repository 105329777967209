import React from "react";
import { API, grphqlOperation, graphqlOperation } from 'aws-amplify'
import { searchMarkets } from '../graphql/queries'
import NewMarket from '../components/NewMarket';
import MarketList from '../components/MarketList';
import Layout from '../Layout';
import GMarketAltList from '../components/GMarketAltList';

class HomePage extends React.Component {
  state = {
    searchTerm: "",
    searchResults: [],
    isSearching: false
  };

  handleSearchChange = (searchTerm) => {
    this.setState({ searchTerm })
  }

  handleClearSearch = () => {
    this.setState({ searchTerm: "", searchResults: [] })

  }

  handleSearch = async (event) => {
    try {
      event.preventDefault()
      this.setState({ isSearching: true })
      const result = await API.graphql(graphqlOperation(searchMarkets, {
        filter: {
          or: [
            { name: { match: this.state.searchTerm } },
            { owner: { match: this.state.searchTerm } },
            { tags: { match: this.state.searchTerm } },
          ]
        },
        sort: {
          field: "name",
          direction: "asc"
        }

      }))

      console.log({ result });
      this.setState({
        searchResults: result.data.searchMarkets.items,
        isSearching: false
      })

    } catch (err) {
      console.error(err);

    }
  }

  render() {
  
    console.log ( "this value of admin in prop is ", this.props.isadmin);
    return ( this.props.isadmin) ? (
      <>
         
        <NewMarket
          admin = {this.props.admin} 
          searchTerm={this.state.searchTerm}
          isSearching={this.state.isSearching}
          handleSearchChange={this.handleSearchChange}
          handleClearSearch={this.handleClearSearch}
          handleSearch={this.handleSearch}
        />
        <MarketList searchResults={this.state.searchResults} />
      </>
    ) : (
      <GMarketAltList searchResults={this.state.searchResults} />
      
    );

  }
}

export default HomePage;
