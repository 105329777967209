import React, { useContext, useEffect } from 'react'
import Benefit from '../Benefits/Benefit'
import { benefits } from '../../utils/constants'

import './Home.css'
const Home = () => {


  const benefitsrow = benefits.map(benefit=> {
    return <Benefit benefit={benefit}/>
  })


  return (
    <div className='home'>
   <div className="benefits">
      {benefitsrow}
    </div>

    </div>
  )
}
export default Home