import React, { useContext } from 'react';
import Layout from './HLayout';

import CartProducts from './CartProducts';
import { CartContext } from './contexts/CartContext';
import { formatNumber, convertPricetoNum  } from '../utils';
import { Link } from 'react-router-dom';
import HPayList from './HPayList'

const HCart = () => {
 
    const { total, cartItems, itemCount, clearCart, checkout, clearCheckout,tax,tprice  } = useContext(CartContext);
    
    return ( 
        <Layout title="Cart" description="This is the Cart page" >
            <div >
                <div className="text-center mt-5">
                  
                   
                </div>

                <div className="row no-gutters justify-content-center">
                    <div className="col-sm-9 p-3"> 
                        {
                            cartItems.length > 0 ?
                            <CartProducts/> :
                            <div className="p-3 text-center text-muted">
                                Your cart is empty
                            </div>
                        }

                        { checkout && 
                            <div className="p-3 text-center text-success">
                                <p>Checkout successfull</p>
                                <Link to="/" className="btn btn-outline-success btn-sm" onClick={clearCheckout}>BUY MORE</Link>
                            </div>
                        }
                    </div>
                    {
                        cartItems.length > 0 && 
                        <div className="col-sm-3 p-3">
                            <div className="card card-body">
                                <p className="mb-1">Items:</p>
                                <h4 className=" mb-3 txt-right">{formatNumber(total)}</h4>
                                <hr className="my-4"/>
                                <p className="mb-1">Total tax</p>
                                <h3 className="m-0 txt-right">{formatNumber(tax)}</h3>
                              
                                <hr className="my-4"/>
                                <p className="mb-1">Total Payment</p>
                                <h3 className="m-0 txt-right">{formatNumber(tprice)}</h3>
                                <hr className="my-4"/>
                                
                                <div className="text-center">
                                
                                <Link to="/HPayList" className="btn btn-outline-success btn-sm">Go to Checkout</Link>
                                
                                <button type="button" className="btn btn-outlineprimary btn-sm" onClick={clearCart}>CLEAR</button>
                                </div>

                            </div>
                        </div>
                    }
                    
                </div>
            </div>
        </Layout>
     );
}
 
export default HCart;