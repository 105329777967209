import React from 'react';
import Icon1 from '../images/svg-1.svg';
import Icon2 from '../images/svg-3.svg';
import Icon3 from '../images/svg-5.svg';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Divider from '@mui/material/Divider';

import {
  ServicesContainer,
  ServicesH1,
  ServicesWrapper,
  ServicesCard,
  ServicesIcon,
  ServicesH2,
  ServicesP,
  ServicesLgIcon, 
  ServicesLgCard
} from './ServicesElements';
import config from '../../aws-exports'

const style = {
  width: '100%',
  maxWidth: 360,
  bgcolor: 'background.paper',
};

const {
  aws_user_files_s3_bucket_region: region,
  aws_user_files_s3_bucket: bucket
} = config


const Services = () => {
  const test = "/public/us-east-1%3A6674e6cd-0af1-4444-abae-58974017d944/public/svg-1.715313cd.svg"
  const test1 = "/public/us-east-1%3A6674e6cd-0af1-4444-abae-58974017d944/public/svg-3.d9e440a5.svg"
  const test2 = "/public/us-east-1%3A6674e6cd-0af1-4444-abae-58974017d944/public/svg-5.494b2abd.svg"

  const url1 = `https://${bucket}.s3.${region}.amazonaws.com/public/${test}`
  const url2 = `https://${bucket}.s3.${region}.amazonaws.com/public/${test1}`
  const url3 = `https://${bucket}.s3.${region}.amazonaws.com/public/${test2}`

  return (
    <ServicesContainer id='services'>
     
      <ServicesWrapper>
        <ServicesCard>
          <ServicesIcon src={url1} />
          
          <ServicesP>
          Since 1993, Bombay Trading Company has served Concord and the surrounding area for all
their South Asian grocery needs, including specialty spices, teas, cookies, dairy products,
sweets, cosmetics.
          </ServicesP>
        </ServicesCard>
        <ServicesCard>
          <ServicesIcon src={url2} />
          
          <ServicesP>
          The current management, which has been in charge for the past 19 years,
strives to create a unique and personalized shopping experience for and treat
customers like family. 
          </ServicesP>
        </ServicesCard>
        <ServicesCard>
          <ServicesIcon src={url3} />
     
          <ServicesP>
          We are committed to providing the best quality products, and are
proud to continue to provide the best services at the oldest and largest Indian grocery store in
the area!
          </ServicesP>
        </ServicesCard>
        
       
        <ServicesCard>
     <ServicesP>
     <List  sx={style} component="nav" aria-label="mailbox folders">
      <ListItem button>
        <ListItemText primary="Concord Location"  secondary="1859 Adobe St, Concord, CA 94520"/>
      </ListItem>
      <Divider />
      <ListItem button divider>
      <ListItemText primary="Phone"  secondary="(925) 825-9043"/>
      </ListItem>
      <ListItem button>
      <ListItemText primary="Email"  secondary="bombaytradingcr@gmail.com"/>
      </ListItem>
      <Divider light />
      <ListItem button>
      <ListItemText primary="Hours"  secondary="12:00PM - 8:00PM"/>
      </ListItem>
    </List>
    
     </ServicesP>
   </ServicesCard>
      </ServicesWrapper>
    </ServicesContainer>
  );
};

export default Services;
