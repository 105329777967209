import React, { useContext } from "react";
import { useRouter } from "next/router";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import HCheckoutForm from "./CheckoutForm";
import { CartContext } from './contexts/CartContext';
import { formatNumber } from '../utils';

// Make sure to call loadStripe outside of a component’s render to avoid
// recreating the Stripe object on every render.
// loadStripe is initialized with your real test publishable API key.
//const promise = loadStripe("pk_test_51HMN3WDLo01jcMScC9EcVCGNoKTyeT93A7QxNv29Kg1GXbf8cy28W1Rg4MFRp6cRykwA4s3VNyTHYxPzKsdETMkv00rGivhOz3");
const promiselive = loadStripe("pk_live_51HMN3WDLo01jcMScKcC6gdTuJxQOiJU0rWbJRsSOsrM7Mis0LAiPTS6XBFsQPn49BYZjWlIFimRbhtrjdfJiDX1Y00Kjp8zNW5")

export default function HPayList() {
  const { total, tax, handleCheckout,tprice } = useContext(CartContext);
  const router = useRouter()

  return (
    <div >
      <Elements stripe={promiselive}>
        <HCheckoutForm price={tprice}  total ={total} tax ={tax}
          onSuccessfulCheckout={() =>  handleCheckout()}
        />
      </Elements>
    </div>
  );
}
