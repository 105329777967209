import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { CartContext } from './contexts/CartContext';
import { formatNumber } from '../utils';
import { convertCentsToDollar, convertDollarsToCents } from '../utils'
import config from '../aws-exports'
import { Notification} from 'element-react'

const {
    aws_user_files_s3_bucket_region: region,
    aws_user_files_s3_bucket: bucket
} = config


const ProductItem = ({product}) => {

    const { addProduct, cartItems, increase,decrease } = useContext(CartContext);
    const url = `https://${bucket}.s3.${region}.amazonaws.com/public/${product.file.key}`

    const isInCart = product => {
        return !!cartItems.find(item => item.id === product.id);
    }

    return ( 
        <div className="card card-body">
            <div className="product-item-img-container">
                <img style={{ display: "block", margin: "0 auto 5px", maxHeight: "170px" }}
                    className="product-item-img ptopbot15 cursor-pointer"
                    src={url} alt="" />
            </div>

            <p className="text-700 mtopbot5 product-item-name ">
                {product.description}
            </p>

            <h5 className="mtop0 mbot5 text-700 tw-scope">
                <span className="bg-primary text-white display-inline-block rounded-corners
                 item-price pleftright7" >${convertCentsToDollar(product.price)}</span>
                  <span className="text-700 text-medium-gray text-medium-smaller

                 item-price pleftright7" >each</span>
                 
            </h5>
            <div className="text-right">
               
                {
                    isInCart(product) && 
                    <button 
                    onClick={() => {increase(product) 
                                    Notification({
                                        title: "success",
                                        message: "Added to cart!",
                                        type: "success"
                                    });
    
                                    }}
                    className="btn btn-outline-primary btn-sm">Add more</button>
                }
                
                {
                    !isInCart(product) && 
                    <button 
                    onClick={() => {addProduct(product)
                                    
                                        Notification({
                                            title: "success",
                                            message: "Added to cart!",
                                            type: "success"
                                        });

                                    
                                    }}
                    className="btn btn-primary btn-sm"> Add cart</button>
                }
                
            </div>
        </div>
     );
}
 
export default ProductItem;