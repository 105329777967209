/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const onCreateMarket = /* GraphQL */ `
  subscription OnCreateMarket($owner: String) {
    onCreateMarket(owner: $owner) {
      id
      name
      products {
        items {
          id
          description
          MarketProducts
          price
          shipped
          owner
          createdAt
          updatedAt
        }
        nextToken
      }
      tags
      owner
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateMarket = /* GraphQL */ `
  subscription OnUpdateMarket($owner: String) {
    onUpdateMarket(owner: $owner) {
      id
      name
      products {
        items {
          id
          description
          MarketProducts
          price
          shipped
          owner
          createdAt
          updatedAt
        }
        nextToken
      }
      tags
      owner
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteMarket = /* GraphQL */ `
  subscription OnDeleteMarket($owner: String) {
    onDeleteMarket(owner: $owner) {
      id
      name
      products {
        items {
          id
          description
          MarketProducts
          price
          shipped
          owner
          createdAt
          updatedAt
        }
        nextToken
      }
      tags
      owner
      createdAt
      updatedAt
    }
  }
`;
export const onCreateProduct = /* GraphQL */ `
  subscription OnCreateProduct($owner: String) {
    onCreateProduct(owner: $owner) {
      id
      description
      MarketProducts
      market {
        id
        name
        products {
          nextToken
        }
        tags
        owner
        createdAt
        updatedAt
      }
      file {
        bucket
        region
        key
      }
      price
      shipped
      owner
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateProduct = /* GraphQL */ `
  subscription OnUpdateProduct($owner: String) {
    onUpdateProduct(owner: $owner) {
      id
      description
      MarketProducts
      market {
        id
        name
        products {
          nextToken
        }
        tags
        owner
        createdAt
        updatedAt
      }
      file {
        bucket
        region
        key
      }
      price
      shipped
      owner
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteProduct = /* GraphQL */ `
  subscription OnDeleteProduct($owner: String) {
    onDeleteProduct(owner: $owner) {
      id
      description
      MarketProducts
      market {
        id
        name
        products {
          nextToken
        }
        tags
        owner
        createdAt
        updatedAt
      }
      file {
        bucket
        region
        key
      }
      price
      shipped
      owner
      createdAt
      updatedAt
    }
  }
`;
export const onCreateOrder = /* GraphQL */ `
  subscription OnCreateOrder {
    onCreateOrder {
      id
      UserOrders
      products {
        items {
          id
          orderMyProduct
          description
          price
          quantity
          shipped
          owner
          createdAt
          updatedAt
        }
        nextToken
      }
      user {
        id
        username
        email
        registered
        admin
        orders {
          nextToken
        }
        createdAt
        updatedAt
        owner
      }
      price
      shipped
      paymentIntent
      status
      shippingAddress {
        city
        country
        address_line1
        address_state
        address_zip
      }
      createdAt
      updatedAt
      owner
    }
  }
`;
export const onUpdateOrder = /* GraphQL */ `
  subscription OnUpdateOrder($owner: String) {
    onUpdateOrder(owner: $owner) {
      id
      UserOrders
      products {
        items {
          id
          orderMyProduct
          description
          price
          quantity
          shipped
          owner
          createdAt
          updatedAt
        }
        nextToken
      }
      user {
        id
        username
        email
        registered
        admin
        orders {
          nextToken
        }
        createdAt
        updatedAt
        owner
      }
      price
      shipped
      paymentIntent
      status
      shippingAddress {
        city
        country
        address_line1
        address_state
        address_zip
      }
      createdAt
      updatedAt
      owner
    }
  }
`;
export const onDeleteOrder = /* GraphQL */ `
  subscription OnDeleteOrder($owner: String) {
    onDeleteOrder(owner: $owner) {
      id
      UserOrders
      products {
        items {
          id
          orderMyProduct
          description
          price
          quantity
          shipped
          owner
          createdAt
          updatedAt
        }
        nextToken
      }
      user {
        id
        username
        email
        registered
        admin
        orders {
          nextToken
        }
        createdAt
        updatedAt
        owner
      }
      price
      shipped
      paymentIntent
      status
      shippingAddress {
        city
        country
        address_line1
        address_state
        address_zip
      }
      createdAt
      updatedAt
      owner
    }
  }
`;
export const onCreateMyProduct = /* GraphQL */ `
  subscription OnCreateMyProduct($owner: String) {
    onCreateMyProduct(owner: $owner) {
      id
      orderMyProduct
      order {
        id
        UserOrders
        products {
          nextToken
        }
        user {
          id
          username
          email
          registered
          admin
          createdAt
          updatedAt
          owner
        }
        price
        shipped
        paymentIntent
        status
        shippingAddress {
          city
          country
          address_line1
          address_state
          address_zip
        }
        createdAt
        updatedAt
        owner
      }
      description
      file {
        bucket
        region
        key
      }
      price
      quantity
      shipped
      owner
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateMyProduct = /* GraphQL */ `
  subscription OnUpdateMyProduct($owner: String) {
    onUpdateMyProduct(owner: $owner) {
      id
      orderMyProduct
      order {
        id
        UserOrders
        products {
          nextToken
        }
        user {
          id
          username
          email
          registered
          admin
          createdAt
          updatedAt
          owner
        }
        price
        shipped
        paymentIntent
        status
        shippingAddress {
          city
          country
          address_line1
          address_state
          address_zip
        }
        createdAt
        updatedAt
        owner
      }
      description
      file {
        bucket
        region
        key
      }
      price
      quantity
      shipped
      owner
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteMyProduct = /* GraphQL */ `
  subscription OnDeleteMyProduct($owner: String) {
    onDeleteMyProduct(owner: $owner) {
      id
      orderMyProduct
      order {
        id
        UserOrders
        products {
          nextToken
        }
        user {
          id
          username
          email
          registered
          admin
          createdAt
          updatedAt
          owner
        }
        price
        shipped
        paymentIntent
        status
        shippingAddress {
          city
          country
          address_line1
          address_state
          address_zip
        }
        createdAt
        updatedAt
        owner
      }
      description
      file {
        bucket
        region
        key
      }
      price
      quantity
      shipped
      owner
      createdAt
      updatedAt
    }
  }
`;
