import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import {  Button} from "element-react";
import { listMarkets } from '../../graphql/queries'
import  { useEffect, useReducer,useContext} from 'react';
import { API } from 'aws-amplify'
import Error from '../Error'
import { animateScroll as scroll } from 'react-scroll';
import { history } from '../../App'
import {CartContext} from '../contexts/CartContext'
import {FaShoppingCart } from 'react-icons/fa';
import { Icon as FIcon  } from "element-react";
import Stack from '@mui/material/Stack';
import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';


import {
  FETCH_DATA_INIT,
  FETCH_DATA_SUCCESS,
  FETCH_DATA_FAILURE,
  RESET_USER_DATA,
} from '../../utils/constants'


import {
  NavLogo,
  SidebarContainer,
  Icon,
  CloseIcon,
  SidebarWrapper,
  SidebarMenu,
  MySidebarLink,
  SidebarRoute,
  SideBtnWrap
} from './SidebarElements';
import MarketList from '../MarketList';




const SideBarReducer = (state, action) => {
  switch (action.type) {
    case FETCH_DATA_INIT:
      return {
        ...state,
        isLoading: true,
        isError: false,
      }
    case FETCH_DATA_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isError: false,
        markets: action.payload.markets,
      }
    case FETCH_DATA_FAILURE:
      return { ...state, isLoading: false, isError: true }
    case RESET_USER_DATA:
      return state
    default:
      throw new Error()
  }
}


const Sidebar = ({ isOpen, toggle, user,  handleSignout, handleSignin  }) => {
  const {itemCount} = useContext(CartContext);


  const initialState = {
    isLoading: true,
    isError: false,
    markets: []
  }

  

  const [state, dispatch] = useReducer(SideBarReducer, initialState)

  const toggleHome = () => {
    scroll.scrollToTop();
    
  };

  useEffect(() => { 
    let isMounted = true;

    const getUserOrders = async () => {
      if (isMounted) {
        dispatch({ type: FETCH_DATA_INIT })
      }
      try {
         const result = await API.graphql( {
          query: listMarkets, 
          authMode: "API_KEY"
          } );

        if (isMounted) {
          dispatch({
            type: FETCH_DATA_SUCCESS,
            payload: { markets: result.data.listMarkets.items },
          })
        }
      } catch (error) {
        dispatch({ type: FETCH_DATA_FAILURE })
      }
    }

    getUserOrders()


    return () => {
      isMounted = false
    }
  },[isOpen]);

  

  const { markets , isLoading, isError } = state

  
  if (isError) return <Error />
  return (
    <SidebarContainer isOpen={isOpen} onClick={toggle} >
      <Icon onClick={toggle}>
        <CloseIcon />
      </Icon>
     
      <SidebarWrapper>
        <SidebarMenu className="productgrid" >

        <Stack direction = "column" 
          spacing={4}  
          divider={<Divider orientation="horizontal" flexItem />}
          alignItems="center" > 
          <SideBtnWrap>
            {!user ? (<SidebarRoute to='/' onClick={handleSignin} >Sign In</SidebarRoute>) :
              (<SidebarRoute to='/' onClick={handleSignout} >Sign Out</SidebarRoute>)}
          </SideBtnWrap>

          <NavLogo onClick={toggleHome} to='/about'>
            About
          </NavLogo>
          <NavLogo onClick={toggleHome} to='/discover'>
            Discover
          </NavLogo>

          <NavLogo onClick={toggleHome} to='/profile'>
            <FIcon name="setting" />
            Profile
          </NavLogo>
      
          <NavLogo onClick={toggleHome} to='/cart'>
            <FaShoppingCart />
            Cart({itemCount})
          </NavLogo>
        
          {markets.map(market => (

            <NavLogo
              key={market.id}
              onClick={toggleHome}
              to={`/markets/${market.id}`} >
              {market.name}
            </NavLogo>
            

          ))
          }
         
             </Stack> 
        </SidebarMenu>
      </SidebarWrapper>
    </SidebarContainer>
  );
};

export default Sidebar;
