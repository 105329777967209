import React from "react";
import { Storage, Auth, API, graphqlOperation } from 'aws-amplify'
import { createProduct } from '../graphql/mutations'

import {AmplifyS3ImagePicker} from "@aws-amplify/ui-react"
// prettier-ignore
import { Form, Button, Input, Notification, Radio, Progress } from "element-react";
import { PhotoPicker } from 'aws-amplify-react';
import { S3Image } from '@aws-amplify/ui-react';
//import { SectionHeader } from "aws-amplify-react/dist/AmplifyUI";

import aws_exports from '../aws-exports'
import { convertDollarsToCents } from '../utils'

const initialState = {
  description: "",
  price: "",
  imagePreview: "",
  image: "",
  shipped: false,
  isUploading: false,
  perecntUploaded: 0

};


class NewProduct extends React.Component {
  state = {
    ...initialState
  };

  handleAddProduct = async () => {

    try {


      this.setState({ isUploading: true })
      const visibility = "public";
      console.log(`file name to be Uploaded: ${filename}`);
      const { identityId } = await Auth.currentCredentials();
      const filename = `/${visibility}/${identityId}/${Date.now()}-${this.state.image.name}`;
      console.log(`file name to be Uploaded: ${filename}`);

      const uploadedFile = await Storage.put(filename, this.state.image.file, {
        contentType: this.state.image.type,
        progressCallback: progress => {
          console.log(`Uploaded: ${progress.loaded} /${progress.total}`);
          const perecntUploaded = Math.round(((progress.loaded) / progress.total) * 100)
          this.setState({ perecntUploaded })
        }
      })

      const file = {
        key: uploadedFile.key,
        bucket: aws_exports.aws_user_files_s3_bucket,
        region: aws_exports.aws_project_region
      }

      const input = {
        MarketProducts: this.props.marketId,
        description: this.state.description,
        shipped: this.state.shipped,
        price: convertDollarsToCents(this.state.price),
        file
      }



      const result = await API.graphql(graphqlOperation(createProduct, { input }))
      console.log('created product', result)

      Notification({
        title: "Success",
        message: " Product sucessfully created!",
        type: "success"
      }
      );
      this.setState({ ...initialState })

    } catch (err) {
      console.error('Error adding product', err);
    }
  }

  fileToKey(data) {
    const { name, size, type } = data;
    console.log (`file name is ${name}`);
    this.setState({ image: name });
    return 'test_' + name;
}
  render() {

    const { description, price, image, shipped, imagePreview, isUploading, perecntUploaded } = this.state;
    return (
      <div className="flex-center">
        <h2 className="header"> Add New Product</h2>
        <div>
          <Form className="market-header">
            <Form.Item label="Add Product Description">
              <Input
                type="text"
                icon="information"
                placeholder="description"
                value={description}
                onChange={description => this.setState({ description })}
              />
            </Form.Item>
            <Form.Item label="Set Product Price">
              <Input
                type="number"
                icon="plus"
                placeholder="Price($USD)"
                value={price}
                onChange={price => this.setState({ price })}
              />
            </Form.Item>
            <Form.Item label="shipped or pickedup">
              <div className="text-center">
                <Radio
                  value="true"
                  checked={shipped === true}
                  onChange={() => this.setState({ shipped: true })}
                >
                  Shipped
                </Radio>
                <Radio
                  value="false"
                  checked={shipped === false}
                  onChange={() => this.setState({ shipped: false })}
                >
                  Emailed
                </Radio>

              </div>
            </Form.Item>
            {imagePreview && (
              <img
                className="image-preview"
                src={imagePreview}
                alt="Product Preview"
              />

            )}

            {perecntUploaded > 0 && (
              <Progress
                type="line"
                className="progress"

                percentage={perecntUploaded}
              />
            )

            }
    
            
              <PhotoPicker
              title="Product Image"
              
              onLoad={url => this.setState({ imagePreview: url })}
              onPick={file => this.setState({ image: file })}
              theme={{
                formContainer: {
                  margin: 0,
                  padding: '0.8em'
                },

                formSection: {
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'center'

                },

                sectionBody: {
                  margin: 0,
                  width: "250px"
                },
                
                sectionHeader: {
                  padding: "0.2em",
                  color: "var(--darkAmazonOrange)"
                },

                photoPickerButton: {
                  display: 'inline-grid',
                  padding: '18px 0',
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  textAlign: 'center',
                  backgroundColor: 'white'
                }

              }}

            />

            <Form.Item>
              <Button
                disabled={!image || !description || !price || isUploading}
                type="primary"
                onClick={this.handleAddProduct}
                loading={isUploading}

              >
                {isUploading ? 'Uploading.. ' : 'Add Product'}
              </Button>
            </Form.Item>

          </Form>
        </div>

      </div>

    )
  }
}

export default NewProduct;
